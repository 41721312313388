import { useCallback } from 'react';
import { useApiFunction, useBusy, useToast } from 'shared';

export function useEditProfile() {
  const toast = useToast();
  const { setBusy } = useBusy();

  const updateSponsor = useApiFunction('portal_sponsors_update');
  const sponsorLogoRemove = useApiFunction('portal_sponsors_logo_remove');
  const setLogoApi = useApiFunction('portal_sponsors_logo_set');

  const editProfileFields = useCallback(
    async (data) => {
      try {
        setBusy(true);
        await updateSponsor({
          sponsorId: data.id,
          data: {
            name: data.name,
            address: {
              ...data.address,
              state: data.address.state.value,
            },
            industry: data.industry,
          },
        });
        toast.success('Profile was edited successfully!');
        return true;
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      } finally {
        setBusy(false);
      }

      return false;
    },
    [toast, updateSponsor, setBusy],
  );

  const removeLogo = useCallback(
    async (id) => {
      try {
        setBusy(true);
        await sponsorLogoRemove({
          sponsorId: id,
          logo: {
            name: 'logo.png',
          },
        });
        toast.success('The logo was successfully removed!');
        return true;
      } catch (e: unknown) {
        setBusy(false);
        if (e instanceof Error) {
          toast.error(e.message);
        }
      } finally {
        setBusy(false);
      }
      return false;
    },
    [toast, sponsorLogoRemove, setBusy],
  );

  const setLogo = useCallback(
    async (id, logoSponsor) => {
      try {
        if (logoSponsor) {
          setBusy(true);
          await await setLogoApi({
            sponsorId: id,
            logo: {
              base64: logoSponsor.uploadContent,
            },
          });
          toast.success('The logo was successfully added!');
          return true;
        }
      } catch (e: unknown) {
        setBusy(false);
        if (e instanceof Error) {
          toast.error(e.message);
        }
      } finally {
        setBusy(false);
      }
      return false;
    },
    [toast, setLogoApi, setBusy],
  );

  return {
    editProfileFields,
    removeLogo,
    setLogo,
  };
}
