import { useCallback, useState, useMemo } from 'react';
import { find } from 'rambda';

import { useOpenClose, useFilteredData } from 'shared/hooks';
import { IEmployee } from 'shared/types';
import { formattedTableData } from '../utils';

export enum ModalType {
  DELETE = 'delete',
  ADD = 'add',
  UPDATE = 'update',
}

export function useEmployeesList({
  fetchEmployees,
  employees,
}: {
  fetchEmployees: () => Promise<void>;
  employees: IEmployee[];
}) {
  const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
  const [employeeId, setEmployeeId] = useState<string | null>(null);
  const { open, onOpen, onClose } = useOpenClose();
  const { setPattern, output } = useFilteredData(employees, [
    'firstName',
    'lastName',
    'phone',
    'email',
  ]);

  const handleAction = useCallback(
    (id: string) => {
      setEmployeeId(id);
      onOpen();
    },
    [onOpen],
  );

  const handleAdd = useCallback(() => {
    setModalType(ModalType.ADD);
    onOpen();
  }, [onOpen]);

  const handleEdit = useCallback(
    (id: string) => {
      handleAction(id);
      setModalType(ModalType.UPDATE);
    },
    [handleAction],
  );

  const handleDelete = useCallback(
    (id: string) => {
      handleAction(id);
      setModalType(ModalType.DELETE);
    },
    [handleAction],
  );

  const data = formattedTableData(
    (output as IEmployee[]).map((e) => ({ ...e, handleDelete, handleEdit })),
  );

  const onUpdate = useCallback(() => {
    onClose();
    fetchEmployees();
  }, [fetchEmployees, onClose]);

  const selectedEmployee = useMemo(
    () => find((e) => e.id === employeeId, output as IEmployee[]),
    [employeeId, output],
  );

  return {
    data,
    onClose,
    open,
    onOpen,
    setPattern,
    onUpdate,
    modalType,
    handleAdd,
    selectedEmployee: selectedEmployee as IEmployee,
  };
}
