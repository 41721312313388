/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Text,
  TextArea as BaseTextArea,
  TextAreaProps,
  BoxExtendedProps,
} from 'grommet';
import { useField, useFormikContext } from 'formik';
import { ErrorOutput } from '../errors';

interface Props extends TextAreaProps {
  name: string;
  label?: string;
  box?: BoxExtendedProps;
  noPadding?: boolean;
  placeholder?: string;
  sizeTextError?: string;
}

export function TextArea(props: Props) {
  const { label, sizeTextError, ...rest } = props;
  const [field, meta] = useField(props);
  const { setFieldValue } = useFormikContext();
  const { touched, error } = meta;

  const onChange = ({ target: { value } }: { target: { value: string } }) => {
    setFieldValue(field.name, value);
  };

  return (
    <Box gap="xxsmall" {...(rest.box || {})}>
      {label && (
        <Text color="light-5" bold size="xsmall">
          {label}
        </Text>
      )}
      <BaseTextArea
        {...field}
        {...rest}
        onChange={onChange}
        hasError={touched && error}
      />
      {touched && error ? <ErrorOutput error={error} /> : null}
    </Box>
  );
}
