/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import {
  Box,
  Text,
  BoxExtendedProps,
  MaskedInputProps,
  MaskedInput as BaseMaskedInput,
} from 'grommet';
import { useField, useFormikContext } from 'formik';

import { ErrorOutput } from '../errors';

interface Props extends Omit<MaskedInputProps, 'onChange'> {
  name: string;
  label?: string;
  box?: BoxExtendedProps;
  noPadding?: boolean;
  placeholder?: string;
}

export function MaskedInput(props: Props) {
  const { label, name, box, mask, size = 'small' } = props;
  const [{ onChange, value, ...field }, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { touched, error } = meta;

  const handleChange = useCallback(
    (v: string) => {
      setFieldValue(field.name, v);
    },
    [field.name, setFieldValue],
  );

  return (
    <Box fill gap="xxsmall" {...(box || {})}>
      {label && (
        <Text color="light-5" weight="bold" size="xsmall">
          {label}
        </Text>
      )}
      <BaseMaskedInput
        size={size}
        mask={mask}
        name={name}
        value={value}
        onChange={(e) => handleChange(e.target.value)}
      />
      {touched && error ? <ErrorOutput error={error} /> : <Text />}
    </Box>
  );
}
