import React from 'react';
import { Box, Text } from 'grommet';
import { equals } from 'rambda';

import { ETypeValues } from 'shared/constants/validationTypes';
import { TextInput } from 'shared/controls';
import { HoursValue } from './HoursValue';
import { DollarValue } from './DollarValue';

export function Value({ value = '' }: { value: ETypeValues | '' }) {
  if (equals(value, ETypeValues.Percentage)) {
    return (
      <Box width="small">
        <TextInput
          box={{
            margin: {
              bottom: 'small',
            },
            align: 'start',
            justify: 'start',
          }}
          name="percentageValue"
          type="number"
          placeholder=""
          reverse
          icon={<Text bold>%</Text>}
        />
      </Box>
    );
  }

  if (equals(value, ETypeValues.Dollar)) {
    return (
      <Box width="small" margin={{ bottom: 'small' }}>
        <DollarValue />
      </Box>
    );
  }

  if (equals(value, ETypeValues.TimeBased)) {
    return (
      <Box width="small" margin={{ bottom: 'small' }}>
        <HoursValue />
      </Box>
    );
  }

  return null;
}
