import { useRef, RefObject } from 'react';
import { Box, Button, Layer } from 'grommet';
import { Print, Qr } from 'grommet-icons';
import QRGenerator from 'react-qr-code';
import { useReactToPrint } from 'react-to-print';

import { IValidation, EValidationStatus } from 'shared/types';
import { useBusy, useOpenClose } from 'shared/hooks';
import { useActiveValidation, useValidationStatus } from '../hooks';
import { ApplySend } from './apply';

type Props = {
  validation: IValidation;
  onUpdate: () => void;
};

export function ActiveActions({ validation, onUpdate }: Props) {
  const { busy } = useBusy();
  const { open, onOpen, onClose } = useOpenClose();
  const { url, id } = validation;
  const { updateCode } = useActiveValidation({ onUpdate });
  const componentRef = useRef<RefObject<HTMLDivElement> | null>(null);
  const handlePrint = useReactToPrint({
    content: () =>
      (componentRef as unknown as RefObject<HTMLDivElement>).current,
  });
  const { changeStatus } = useValidationStatus({ onSuccess: onUpdate });
  return (
    <>
      <Box width="large" align="center">
        <Box width="small" alignSelf="center" margin={{ bottom: 'small' }}>
          <Button
            primary
            color="black"
            label="Disabled"
            onClick={() => {
              changeStatus(EValidationStatus.DISABLED, id);
            }}
            disabled={busy}
          />
        </Box>
        <Box
          pad="xsmall"
          background="white"
          border="all"
          elevation="medium"
          margin={{ bottom: 'medium' }}
        >
          <div ref={componentRef as RefObject<HTMLDivElement>}>
            <QRGenerator value={url} />
          </div>
        </Box>

        <Box>
          <Box margin={{ top: 'xsmall' }}>
            <Button
              disabled={busy}
              roundSmall
              primary
              label="Apply or Send"
              onClick={onOpen}
            />
          </Box>
          <Box margin={{ top: 'xsmall' }}>
            <Button
              disabled={busy}
              icon={<Print size="medium" />}
              roundSmall
              label="Print"
              onClick={handlePrint}
            />
          </Box>
          <Box margin={{ top: 'xsmall' }}>
            <Button
              disabled={busy}
              roundSmall
              icon={<Qr />}
              label="New QR"
              onClick={() => updateCode(id)}
            />
          </Box>
        </Box>
      </Box>
      {open && (
        <Layer
          position="center"
          animation="fadeIn"
          modal
          onClickOutside={onClose}
          onEsc={onClose}
        >
          <ApplySend validation={validation} onClose={onClose} />
        </Layer>
      )}
    </>
  );
}
