import React from 'react';
import { Box } from 'grommet';

import { authenticatedRoutes } from 'App/routes';
import { StyledLink as Link } from '../styled';

export function Navigation(): JSX.Element {
  return (
    <Box pad={{ horizontal: 'small' }} flex="grow">
      {authenticatedRoutes.map(({ label, path, showInMenu, icon: Icon }) =>
        showInMenu ? (
          <Link key={label} to={path} title={label} activeClassName="active">
            <Box gap="xsmall" direction="row" align="center">
              {Icon}
              <span>{label}</span>
            </Box>
          </Link>
        ) : null,
      )}
    </Box>
  );
}
