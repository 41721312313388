export default {
  tab: {
    margin: 'none',
    active: {
      background: '#333333',
    },
    border: undefined,
    hover: {
      color: 'light-4',
    },
    pad: {
      horizontal: 'xlarge',
      vertical: 'none',
      bottom: 'none',
    },
    extend: () => `
      border-radius: 40px;
      outline: 0;
    `,
  },
  tabs: {
    background: 'inherit',
    panel: {
      extend: () => `
       width: 100%;
      `,
    },
    header: {
      background: 'light-3',
      extend: () => `
        border-radius: 40px;
        padding: 0;
        justify-content: space-between;
      `,
    },
  },
};
