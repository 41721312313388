import React from 'react';
import { Image as Icon } from 'grommet-icons';
import { Text, Box, Image } from 'grommet';
import { IFileContent } from 'shared/types';

export function LogoProfile({
  logo,
  onClick,
}: {
  logo?: IFileContent | string;
  onClick?: () => void;
}) {
  return (
    <Box>
      {logo ? (
        <Box
          width="148px"
          height="148px"
          align="center"
          justify="center"
          border={{
            color: 'black',
          }}
          overflow="hidden"
          round="small"
          background="white"
          onClick={onClick}
        >
          <Image
            src={typeof logo === 'string' ? logo : logo.preview}
            fit="contain"
            title="Click to remove"
          />
        </Box>
      ) : (
        <Box align="center" justify="center">
          <Icon color="light-6" size="medium" />
          <Text
            as="div"
            color="light-6"
            margin={{ top: 'xxsmall' }}
            textAlign="center"
            size="xsmall"
          >
            Add Logo
          </Text>
          <Text as="div" color="light-6" textAlign="center" size="xxsmall">
            (PNG, up to 1M)
          </Text>
        </Box>
      )}
    </Box>
  );
}
