export const fieldStyle = `
  font-size: 16px;
  font-weight: bold;
  outline: 0;
  padding: 12px 20px;
  border-color: #e9e9e9;
  color: #2e2e2e;
  background-color: #e9e9e9;
  transition: all 0.2s;
  &:active {
    border-color: #e9e9e9;
    box-shadow: none !important;
    background-color: #ffffff;
  }
  &:focus {
    border-color: #e9e9e9;
    box-shadow: none !important;
    background-color: #ffffff;
  }
`;

const textInput = {
  textInput: {
    extend: (props) => {
      let extraStyles = '';
      if (props.hasError) {
        extraStyles = `
            ${extraStyles}
            border-color: #FF006A;
          `;
      }
      if (props.noPadding) {
        extraStyles = `
            ${extraStyles}
            padding : 5px 5px;
            background-color: #F2F2F2;
            text-align: center;
          `;
      }
      if (props.colorInput) {
        extraStyles = `
            ${extraStyles}
            padding : 0;
            cursor: pointer;
            height: 35px;
            border: none;
            background-color: inherit;
          `;
      }
      if (props.smallPadding) {
        extraStyles = `
            ${extraStyles}
            padding : 5px 5px;
            text-align: center;
          `;
      }
      if (props.alignCenter) {
        extraStyles = `
            ${extraStyles}
            text-align: center;
          `;
      }
      if (props.static) {
        extraStyles = `
            ${extraStyles}
            &:disabled {
              opacity: 1;
            }
          `;
      }
      return !props.custom
        ? `
          ${fieldStyle}
          ${extraStyles}
        `
        : ``;
    },
  },
};

const textArea = {
  textArea: {
    extend: (props) => {
      let extraStyles = '';
      if (props.hasError) {
        extraStyles = `
            ${extraStyles}
            border-color: #FF006A;
          `;
      }
      if (props.minHeight) {
        extraStyles = `
            ${extraStyles}
            min-height: ${props.minHeight};
          `;
      }
      if (props.marginBottom) {
        extraStyles = `
            ${extraStyles}
            margin-bottom: ${props.marginBottom};
          `;
      }
      return !props.custom
        ? `
          ${fieldStyle}
          ${extraStyles}
        `
        : ``;
    },
  },
};

const checkAndRadio = `
  outline: 0;
  box-shadow: none !important;
  border-color: #D7D3D3;
`;

const checkBox = {
  checkBox: {
    gap: '8px',
    extend: () => {
      return `
        ${checkAndRadio}
      `;
    },
    icon: {
      extend: () => {
        return `
        ${checkAndRadio}
      `;
      },
    },
    check: {
      extend: () => {
        return `
        ${checkAndRadio}
      `;
      },
    },
  },
};

const radioButton = {
  radioButton: {
    gap: '8px',
    extend: () => {
      return `
        ${checkAndRadio}
      `;
    },
    icon: {
      extend: () => {
        return `
        ${checkAndRadio}
      `;
      },
    },
    check: {
      extend: () => {
        return `
        ${checkAndRadio}
      `;
      },
    },
  },
};

const maskedInput = {
  maskedInput: {
    extend: (props) => {
      let extraStyles = '';
      if (props.alignCenter) {
        extraStyles = `
            ${extraStyles}
            text-align: center;
          `;
      }
      if (props.small) {
        extraStyles = `
            ${extraStyles}
            height: 32px;
            width: 140px;
          `;
      }
      return `
          ${extraStyles}
          outline: 0 !important;
          box-shadow: none !important;
          border-color: #D7D3D3;
        `;
    },
  },
};

export default {
  ...textInput,
  ...textArea,
  ...checkBox,
  ...maskedInput,
  ...radioButton,
};
