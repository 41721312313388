import React from 'react';
import { Box, Button } from 'grommet';

import { EValidationStatus } from 'shared/types';
import { useBusy } from 'shared/hooks';

type Props = {
  changeStatus: (status: EValidationStatus) => void;
};

export function Activate({ changeStatus }: Props) {
  const { busy } = useBusy();
  return (
    <>
      <Box width="small" margin={{ bottom: '0.5rem' }}>
        <Button
          fill
          primary
          label="Activate"
          onClick={() => {
            changeStatus(EValidationStatus.ACTIVE);
          }}
          disabled={busy}
        />
      </Box>
      <Box width="small">
        <Button
          secondary
          fill
          label="Disable"
          onClick={() => {
            changeStatus(EValidationStatus.DISABLED);
          }}
          disabled={busy}
        />
      </Box>
    </>
  );
}
