import React from 'react';
import { Box, Heading } from 'grommet';

export function PageHeader({ title }: { title: string }) {
  return (
    <Box
      align="start"
      pad={{ horizontal: 'none', top: 'small', bottom: 'none' }}
    >
      <Heading
        level="2"
        color="dark-1"
        textAlign="center"
        margin={{
          bottom: 'none',
        }}
      >
        {title}
      </Heading>
    </Box>
  );
}
