/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useCallback } from 'react';
import { isEmpty, map, prop, complement } from 'rambda';
import { useDropzone } from 'react-dropzone';
import {} from 'grommet';

import { useToast } from 'shared/hooks';
import { Container } from './Styled';

const notEmpty = complement(isEmpty);

const defaultMaxSize = 1e6; // 1 Mb
const defaultAccepted = 'image/png';

interface Props {
  onUpload: (files: File[]) => void;
  className?: string;
  children?: JSX.Element;
  fileTypes?: string;
  maxSize?: number;
  noClick?: boolean;
  noKeyboard?: boolean;
  multiple?: boolean;
  disabled?: boolean;
  hideErrors?: boolean;
}

export function FilesUpload({
  onUpload,
  children,
  maxSize = defaultMaxSize,
  fileTypes = defaultAccepted,
  noClick = false,
  noKeyboard = true,
  multiple = false,
  disabled = false,
  hideErrors = false,
}: Props) {
  const toast = useToast();
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (notEmpty(acceptedFiles)) {
        onUpload(acceptedFiles);
      }
    },
    [onUpload],
  );
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections,
  } = useDropzone({
    accept: fileTypes,
    noClick,
    noKeyboard,
    maxSize,
    multiple,
    onDrop,
    disabled,
  });

  const rejectedFiles = useMemo(() => {
    if (notEmpty(fileRejections)) {
      return map(({ file }) => prop('name', file), fileRejections);
    }
    return '';
  }, [fileRejections]);

  useEffect(() => {
    if (rejectedFiles && !hideErrors) {
      toast.error('Invalid file format or max file size exceeded.');
    }
  }, [hideErrors, rejectedFiles, toast]);

  return (
    <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })}>
      <>
        <input {...getInputProps()} />
        {children}
      </>
    </Container>
  );
}
