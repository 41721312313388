import { useCallback } from 'react';
import 'firebase/auth';

import { useApiFunction, useToast, IFileContent } from 'shared';

export function useSignup({ logo }: { logo?: IFileContent }) {
  const register = useApiFunction('portal_sponsors_registration');
  const toast = useToast();

  const signup = useCallback(
    async ({ address, ...rest }) => {
      try {
        await register({
          ...rest,
          address: { ...address, state: address.state.value },
          ...(logo && {
            logo: {
              base64: logo.uploadContent,
            },
          }),
        });
        toast.success('Registered! Please, check your email!');
        return true;
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
      return false;
    },
    [logo, register, toast],
  );

  return {
    signup,
  };
}
