import React from 'react';
import { Box, Button } from 'grommet';
import { Formik, Form } from 'formik';
import PhoneInput from 'react-phone-number-input';

import { TextInput } from 'shared/controls';
import { TEmployee, employeeSchema } from 'shared/validations';

interface Props {
  initialValues: TEmployee;
  handleSubmit: (data: TEmployee) => void;
  busy?: boolean;
  onCancel: () => void;
}

export function UserForm(props: Props) {
  const { initialValues, handleSubmit, busy, onCancel } = props;
  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      validationSchema={employeeSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(false);
        await handleSubmit(values);
      }}
    >
      {({ isValid, setFieldValue, values }) => (
        <Form>
          <Box margin={{ bottom: 'none' }}>
            <Box gap="small" direction="row" fill>
              <TextInput
                box={{
                  margin: {
                    bottom: 'small',
                  },
                  basis: '50%',
                }}
                name="firstName"
                type="text"
                placeholder="First Name"
              />
              <TextInput
                box={{
                  margin: {
                    bottom: 'small',
                  },
                  basis: '50%',
                }}
                name="lastName"
                type="text"
                placeholder="Last Name"
              />
            </Box>
            <Box gap="small" direction="row" fill margin={{ bottom: 'small' }}>
              <Box basis="50%">
                <PhoneInput
                  defaultCountry="US"
                  country="US"
                  onChange={(v: string) => setFieldValue(`phone`, v)}
                  placeholder="Enter phone number"
                  value={values.phone}
                />
              </Box>
              <TextInput
                box={{
                  margin: {
                    bottom: 'small',
                  },
                  basis: '50%',
                }}
                name="email"
                type="text"
                placeholder="Email"
              />
            </Box>
            <Box gap="small" direction="row" fill align="start">
              <Button
                primary
                roundSmall
                label="Submit"
                type="submit"
                disabled={busy || !isValid}
              />
              <Button
                roundSmall
                type="button"
                label="Close"
                onClick={onCancel}
              />
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
