export const palette = {
  blueAction: '#09bcef',
  blueHover: '#089abc',
  blueDark: '#004974',
  grayMedium: '#959699',
  grayLight: '#f6f7f9',
  gold: '#fcb600',
};

export default {
  colors: {
    icon: {
      0: '#',
      1: '6',
      2: '6',
      3: '6',
      4: '6',
      5: '6',
      6: '6',
      dark: '#f8f8f8',
      light: '#666666',
    },
    background: '#f9f9f9',
    active: 'rgba(221,221,221,0.5)',
    black: '#000000',
    border: {
      dark: 'rgba(255,255,255,0.33)',
      light: 'rgba(0,0,0,0.33)',
    },
    brand: '#7797FF',
    control: {
      dark: 'accent-1',
      light: 'dark-1',
    },
    focus: '#000000',
    placeholder: '#AAAAAA',
    selected: 'brand',
    text: {
      dark: '#f8f8f8',
      light: '#2e2e2e',
    },
    white: '#FFFFFF',
    'accent-1': '#4696FF',
    'accent-2': '#FFA24F',
    'accent-3': '#81FCED',
    'accent-4': '#FFCA58',
    'dark-1': '#2e2e2e',
    'dark-2': '#585858',
    'dark-3': '#070826',
    'dark-4': '#666666',
    'dark-5': '#888888',
    'dark-6': '#999999',
    'light-1': '#f7f7f7',
    'light-2': '#e3e3e3',
    'light-3': '#EDEDED',
    'light-4': '#AEADAD',
    'light-5': '#b9b9b9',
    'light-6': '#919191',
    'light-7': palette.grayLight,
    'neutral-1': palette.gold,
    'neutral-2': '#3D138D',
    'neutral-3': '#00739D',
    'neutral-4': '#A2423D',
    'status-critical': '#D758A4',
    'status-error': '#FF006A',
    'status-warning': '#E8891A',
    'status-ok': '#36C770',
    'status-unknown': '#CCCCCC',
    'status-disabled': '#CCCCCC',
  },
};
