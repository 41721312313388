import React, { createContext, useContext } from 'react';
import app from 'firebase/app';
import 'firebase/analytics';

const FirebaseContext = createContext<app.app.App | null>(null);
export { FirebaseContext };
export const useFirebase = () => useContext(FirebaseContext) as app.app.App;

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
} = process.env;

export default ({ children }: { children: React.ReactNode }): JSX.Element => {
  const portal = !app.apps.length
    ? app.initializeApp({
        apiKey: REACT_APP_FIREBASE_API_KEY,
        authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
        projectId: REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: REACT_APP_FIREBASE_APP_ID,
        measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
      })
    : app.apps[0];
  portal.analytics();
  return (
    <FirebaseContext.Provider value={portal}>
      {children}
    </FirebaseContext.Provider>
  );
};
