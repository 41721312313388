import * as Yup from 'yup';
import { isValidNumber } from 'libphonenumber-js';

export const selectValidation = (message = 'Required') =>
  Yup.object().shape({
    value: Yup.string().required(message),
  });

export const requiredString = (message = 'Required') =>
  Yup.string().required(message);

export const checkPhoneNumber = (value: string | undefined) => {
  let isValidPhone = false;
  try {
    isValidPhone = isValidNumber(value as string);
  } catch (error) {
    isValidPhone = false;
  }
  return isValidPhone;
};

export const phoneNumberValidator = Yup.string()
  .required('Phone number is required')
  .test('test-name', 'Enter Valid Phone Number', checkPhoneNumber);
