import React, { useState, useEffect } from 'react';
import { Box, TextInput } from 'grommet';
import { Search } from 'grommet-icons';

type Props = {
  placeholder?: string;
  searchName?: string;
  onChange: (v: string) => void;
};

export function SearchInput(props: Props) {
  const { onChange, placeholder = '', searchName = '' } = props;
  const [val, setValue] = useState(searchName || '');
  const [focus, setFocus] = useState(false);
  useEffect(() => {
    onChange(val);
  }, [onChange, val]);
  return (
    <Box
      direction="row"
      align="center"
      background={focus ? 'white' : 'light-3'}
      round="medium"
      pad={{
        vertical: 'xxsmall',
        horizontal: 'small',
      }}
      width="large"
      margin="none"
      animation="fadeIn"
    >
      <Search color="light-4" />
      <TextInput
        style={{ boxShadow: 'none' }}
        type="search"
        value={val}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        plain
        custom
        onChange={({ target: { value } }) => setValue(value)}
        placeholder={placeholder}
      />
    </Box>
  );
}
