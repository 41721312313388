import { Box, Text } from 'grommet';

import { TEmployee } from 'shared/validations';
import { UserForm } from './UserForm';
import { useSetEmployee } from '../hooks';

type Props = {
  onClose: () => void;
  onUpdate: () => void;
};

const NEW_USER: TEmployee = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

export function AddUser({ onClose, onUpdate }: Props) {
  const { setEmployee, busy } = useSetEmployee({
    onSuccess: onUpdate,
  });
  return (
    <Box
      width="large"
      background="white"
      pad={{ vertical: 'large', horizontal: 'large' }}
      round="xxsmall"
    >
      <Text
        as="h2"
        size="large"
        textAlign="center"
        margin={{
          top: 'none',
          bottom: 'medium',
        }}
      >
        Add Employee
      </Text>
      <UserForm
        initialValues={NEW_USER}
        onCancel={onClose}
        handleSubmit={setEmployee}
        busy={busy}
      />
    </Box>
  );
}
