/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { Box, Text, Select, SelectProps, BoxExtendedProps } from 'grommet';
import { useField, useFormikContext } from 'formik';

import { ErrorOutput } from '../errors';

interface Props extends SelectProps {
  name: string;
  label?: string;
  box?: BoxExtendedProps;
  noPadding?: boolean;
  placeholder?: string;
}

export function SelectInput(props: Props) {
  const { label, name, box, ...rest } = props;
  const [{ onChange, ...field }, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { touched, error } = meta;

  const handleChange = useCallback(
    ({ option }) => {
      setFieldValue(field.name, option);
    },
    [field.name, setFieldValue],
  );

  return (
    <Box fill gap="xxsmall" {...(box || {})}>
      <Select
        {...field}
        {...rest}
        hasError={touched && error}
        onChange={handleChange}
      />
      {touched && error ? (
        <ErrorOutput error={error} />
      ) : (
        label && (
          <Text color="light-5" bold size="xsmall">
            {label}
          </Text>
        )
      )}
    </Box>
  );
}
