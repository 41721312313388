import { EValidationStatus } from 'shared/types';

interface StatusData {
  label: string;
  color: string;
}

export const statuses: Record<EValidationStatus, StatusData> = {
  [EValidationStatus.ACTIVE]: {
    label: 'active',
    color: 'status-ok',
  },
  [EValidationStatus.DISABLED]: {
    label: 'disabled',
    color: 'status-disabled',
  },
  [EValidationStatus.AWAITING_CONFIRMATION]: {
    label: 'awaiting confirmation',
    color: 'status-warning',
  },
  [EValidationStatus.READY_TO_ACTIVATE]: {
    label: 'ready to activate',
    color: 'status-critical',
  },
};
