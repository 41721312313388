import { Box, Button, Text, Spinner } from 'grommet';
import { Formik, Form } from 'formik';
import { useHistory, Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';

import { validateSignup } from 'shared/validations';
import { TextInput, SelectInput } from 'shared/controls';
import { FilesUpload, LogoProfile, useFileUpload } from 'shared';
import states from 'shared/constants/states';
import { RouteName, generateRoutePath } from 'App/routes';
import { useSignup } from './hooks';

export function Signup() {
  const { onUpload, file, removeFiles } = useFileUpload();
  const { signup } = useSignup({ logo: file });
  const { push } = useHistory();
  const loginPath = generateRoutePath({ name: RouteName.Home });
  return (
    <Formik
      initialValues={{
        name: '',
        address: {
          zip: '',
          city: '',
          state: '',
          country: '',
          street: '',
        },
        industry: '',
        employee: {
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
        },
      }}
      validationSchema={validateSignup}
      onSubmit={async (values, { setSubmitting }) => {
        const res = await signup(values);
        setSubmitting(false);
        return res && push(loginPath);
      }}
    >
      {({ isSubmitting, isValid, values, setFieldValue }) => (
        <Form>
          <Text as="h2" size="large">
            Please, Sign Up
          </Text>
          <Text as="p" size="small">
            Have an account? <Link to={loginPath}>Sign In</Link>
          </Text>

          <Box margin={{ bottom: 'medium' }}>
            <Box gap="small" direction="row" fill margin={{ bottom: 'small' }}>
              <Box basis="33%">
                <FilesUpload onUpload={onUpload}>
                  <LogoProfile logo={file} onClick={removeFiles} />
                </FilesUpload>
              </Box>

              <Box basis="67%">
                <TextInput
                  box={{
                    margin: {
                      bottom: 'small',
                    },
                  }}
                  name="name"
                  type="text"
                  placeholder="Company Name"
                />
                <TextInput name="industry" type="text" placeholder="Industry" />
              </Box>
            </Box>

            <Box as="div" position="relative" margin={{ bottom: 'medium' }}>
              <Text middleLine="-42px" color="#666666" size="small">
                Address
              </Text>
              <hr />
            </Box>
            <Box>
              <Box gap="small" direction="row" fill>
                <TextInput
                  box={{
                    margin: {
                      bottom: 'small',
                    },
                    basis: '50%',
                  }}
                  name="address.city"
                  type="text"
                  placeholder="City Name"
                />
                <TextInput
                  box={{
                    margin: {
                      bottom: 'small',
                    },
                    basis: '50%',
                  }}
                  name="address.street"
                  type="text"
                  placeholder="Street"
                />
              </Box>
              <Box gap="small" direction="row" fill>
                <SelectInput
                  box={{
                    margin: {
                      bottom: 'small',
                    },
                    basis: '33%',
                  }}
                  labelKey="text"
                  valueKey="value"
                  name="address.state"
                  options={states}
                  dropHeight="small"
                  placeholder="State"
                />
                <TextInput
                  box={{
                    margin: {
                      bottom: 'small',
                    },
                    basis: '33%',
                  }}
                  name="address.country"
                  type="text"
                  placeholder="Country"
                />
                <TextInput
                  box={{
                    margin: {
                      bottom: 'small',
                    },
                    basis: '33%',
                  }}
                  name="address.zip"
                  type="text"
                  placeholder="Zip Code"
                />
              </Box>
            </Box>
            <Box as="div" position="relative" margin={{ bottom: 'medium' }}>
              <Text middleLine="-72px" color="#666666" size="small">
                Account Details
              </Text>
              <hr />
            </Box>
            <Box gap="small" direction="row" fill>
              <TextInput
                box={{
                  margin: {
                    bottom: 'small',
                  },
                  basis: '50%',
                }}
                name="employee.firstName"
                type="text"
                placeholder="First Name"
              />
              <TextInput
                box={{
                  margin: {
                    bottom: 'small',
                  },
                  basis: '50%',
                }}
                name="employee.lastName"
                type="text"
                placeholder="Last Name"
              />
            </Box>
            <Box gap="small" direction="row" fill>
              <TextInput
                box={{
                  margin: {
                    bottom: 'small',
                  },
                  basis: '50%',
                }}
                name="employee.email"
                type="text"
                placeholder="Email"
              />
              <Box basis="50%">
                <PhoneInput
                  international
                  defaultCountry="US"
                  country="US"
                  onChange={(v: string) => setFieldValue('employee.phone', v)}
                  placeholder="Enter phone number"
                  value={values.employee.phone}
                />
              </Box>
            </Box>
          </Box>
          <Box
            direction="row"
            justify="center"
            gap="medium"
            margin={{ top: 'medium' }}
          >
            {isSubmitting ? (
              <Box align="center" direction="row" gap="small">
                <Spinner
                  border={[
                    {
                      side: 'all',
                      color: 'black',
                      size: 'medium',
                      style: 'dotted',
                    },
                  ]}
                />
                <Text>Processing...</Text>
              </Box>
            ) : (
              <Button
                type="submit"
                label="Create Company"
                size="large"
                primary
                roundSmall
                disabled={isSubmitting || !isValid}
              />
            )}
          </Box>
        </Form>
      )}
    </Formik>
  );
}
