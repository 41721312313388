import { useEffect, useState } from 'react';
import { Box, Image, Text, Button } from 'grommet';
import styled from 'styled-components';

import { useSession } from 'App/session';
import { useGetSponsorData, getDownloadUrl } from 'shared';
import { RouteName, useGotoRoute } from 'App/routes';

const LogoHolder = styled(Box)`
  width: 152px;
  height: 152px;
  min-height: 152px;
  border-radius: 6px;
`;

const EditButton = styled(Button)`
  background-color: #333;
  padding: 5px 25px;
  border-radius: 20px;
`;

export function SidebarHeader() {
  const { user } = useSession();
  const { getSponsorData, sponsorData } = useGetSponsorData();
  const { logo, id } = sponsorData ?? {};
  const [logoUrl, setLogoUrl] = useState<string>();
  const goEditProfile = useGotoRoute(RouteName.EditProfile);

  useEffect(() => {
    getSponsorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getSponsor = async () => {
      if (logo && id) {
        setLogoUrl(await getDownloadUrl(`/sponsorLogos/${id}/logo.png`));
      } else {
        setLogoUrl('');
      }
    };
    getSponsor();
    return () => {
      getSponsor();
    };
  }, [logo, id]);

  return (
    <Box
      direction="column"
      width="full"
      align="center"
      pad={{
        top: 'xlarge',
        bottom: 'medium',
      }}
    >
      <LogoHolder
        background="white"
        align="center"
        pad="small"
        justify="center"
        overflow="hidden"
      >
        {logoUrl ? (
          <Image width="100%" src={logoUrl} alt="" fit="contain" />
        ) : (
          <Text textAlign="center">Company Logo</Text>
        )}
      </LogoHolder>
      <Box
        width="full"
        pad={{ horizontal: 'small' }}
        margin={{ top: 'small', bottom: 'xsmall' }}
      >
        <Text truncate as="div" textAlign="center">
          {user && user.displayName}
        </Text>
      </Box>
      <Box width="full" pad={{ horizontal: 'small' }}>
        <Text bold truncate as="div" textAlign="center">
          {sponsorData?.name}
        </Text>
      </Box>
      <Box
        width="full"
        pad={{ horizontal: 'small' }}
        margin={{ top: 'small', bottom: 'xsmall' }}
        align="center"
      >
        <EditButton
          label={<Text size="xsmall">EDIT</Text>}
          size="small"
          gap="small"
          plain
          onClick={() => goEditProfile()}
          disabled={false}
        />
      </Box>
    </Box>
  );
}
