import { Box, Button } from 'grommet';
import styled from 'styled-components';
import { Logout } from 'grommet-icons';

import { useSession } from 'App/session';
import { SidebarHeader } from './SidebarHeader';
import { Navigation } from './Navigation';

type TWrapper = {
  hasSideBar: boolean;
};

const Wrapper = styled(Box)<TWrapper>`
  position: fixed;
  top: 0;
  bottom: 0;
  /* left: ${(props) => (props.hasSideBar ? '0px' : '-300px')}; */
  left: -300px;
  transition: left 0.3s;
  z-index: 10;
  width: 300px;
  min-width: 300px;
  min-height: 400px;
  @media screen and (min-width: 992px) {
    position: relative;
    left: 0;
    top: unset;
    bottom: unset;
  }
`;

export function Sidebar() {
  const { signout } = useSession();
  return (
    <Wrapper
      gridArea="sidebar"
      background="#000000"
      hasSideBar
      direction="column"
    >
      <SidebarHeader />
      <Navigation />
      <Button
        icon={<Logout size="medium" />}
        reverse
        plain
        label="Sign out"
        onClick={signout}
        margin={{ vertical: 'small' }}
      />
    </Wrapper>
  );
}
