import { useCallback } from 'react';
import { useApiFunction, useToast, useGetSponsorData, useBusy } from 'shared';

export type TUpdateCode = (validationId: string) => Promise<boolean>;

export interface ReturnValue {
  updateCode: TUpdateCode;
}

export function useActiveValidation({
  onUpdate = () => {},
}: {
  onUpdate?: () => void;
}): ReturnValue {
  const { getSponsor } = useGetSponsorData();
  const toast = useToast();
  const { setBusy } = useBusy();
  const updateCodeFn = useApiFunction('portal_validations_update_code');

  const updateCode = useCallback(
    async (validationId: string) => {
      try {
        setBusy(true);
        const { sponsorId } = await getSponsor();
        await updateCodeFn({
          sponsorId,
          validationId,
        });
        toast.success('New QR-code has been created!');
        onUpdate();
        return true;
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
        return false;
      } finally {
        setBusy(false);
      }
    },
    [getSponsor, onUpdate, setBusy, toast, updateCodeFn],
  );

  return {
    updateCode,
  };
}
