import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Formik, Form } from 'formik';
import { pathOr } from 'rambda';

import { validateValidation, TValidation } from 'shared/validations';
import types from 'shared/constants/validationTypes';
import { TextArea, SelectField } from 'shared/controls';
import { useAddValidation } from '../hooks';
import { Value } from './typeValue';

export function CreateValidation({ onSuccess }: { onSuccess: () => void }) {
  const { options, saveValidation } = useAddValidation({ onSuccess });
  return (
    <Box pad="large" width="large" margin="auto">
      <Formik
        initialValues={{
          garage: '',
          type: '',
          description: '',
          dollarValue: '',
          percentageValue: '',
          hoursValue: '',
          customValue: [],
          basePrice: '',
        }}
        validationSchema={validateValidation}
        onSubmit={async (values, { setSubmitting }) => {
          await saveValidation(values as unknown as TValidation);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <Text
              as="h2"
              size="large"
              textAlign="center"
              margin={{
                top: 'none',
                bottom: 'medium',
              }}
            >
              Create Validation
            </Text>
            <Box>
              <SelectField
                box={{
                  margin: {
                    bottom: 'small',
                  },
                }}
                name="garage"
                options={options}
                label="Select a Garage"
              />
              <SelectField
                box={{
                  margin: {
                    bottom: 'small',
                  },
                }}
                name="type"
                options={types}
                label="Type"
              />
              <Value value={pathOr('', 'type', values)} />
              <TextArea
                box={{
                  margin: {
                    bottom: 'small',
                  },
                }}
                resize={false}
                name="description"
                label="Description"
              />
              <Box align="start">
                <Button
                  primary
                  color="brand"
                  label={isSubmitting ? 'Submitting...' : 'Submit for Approval'}
                  size="large"
                  type="submit"
                  roundSmall
                  disabled={isSubmitting || !isValid}
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
