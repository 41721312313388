import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { Text, Box, Spinner, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { TextInput, SelectInput } from 'shared/controls';
import {
  PageWrapper,
  FilesUpload,
  useFileUpload,
  PageHeader,
  useGetSponsorData,
  getDownloadUrl,
  LogoProfile,
} from 'shared';
import states from 'shared/constants/states';
import { validateEditProfile } from 'shared/validations';
import { useEditProfile } from './hooks';
import { CloseButton, WrapperLogo } from './EditProfile.styles';

export interface IProfileData {
  name: string;
  address: {
    zip: string;
    city: string;
    state: {
      value: string;
      text: string;
    };
    country: string;
    street: string;
  };
  industry: string;
  id: string;
  logo: string;
}

export const EditProfile = () => {
  const { onUpload, file, removeFiles } = useFileUpload();
  const { editProfileFields, removeLogo, setLogo } = useEditProfile();
  const { getSponsorData, sponsorData } = useGetSponsorData();
  const [logoUrl, setLogoUrl] = useState<string>();
  const [initialSponsorData, setInitialSponsorData] = useState<IProfileData>({
    name: '',
    address: {
      zip: '',
      city: '',
      state: {
        value: '',
        text: '',
      },
      country: '',
      street: '',
    },
    industry: '',
    id: '',
    logo: '',
  });

  const { name, address, industry, logo, id } = initialSponsorData ?? {};
  const initialState = {
    name,
    address,
    industry,
  };

  useEffect(() => {
    getSponsorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLogo(id, file);
  }, [id, setLogo, file]);

  useEffect(() => {
    const getSponsor = async () => {
      if (sponsorData) {
        setInitialSponsorData({
          ...(sponsorData as IProfileData),
          address: {
            ...sponsorData.address,
            state: states.find((s) => s.value === sponsorData.address.state),
          },
        });
      }
      if (sponsorData?.logo && sponsorData?.id) {
        setLogoUrl(await getDownloadUrl(`/sponsorLogos/${id}/logo.png`));
      } else {
        setLogoUrl('');
      }
    };
    getSponsor();
  }, [id, logo, sponsorData]);

  async function handleSubmit(values: Omit<IProfileData, 'id' | 'logo'>) {
    await editProfileFields({ ...values, id });
  }
  const handleRemoveLogo = () => {
    removeLogo(id);
    setLogoUrl('');
    removeFiles();
  };

  return (
    <PageWrapper>
      <PageHeader title="Edit Profile" />
      <Formik
        initialValues={initialState}
        validationSchema={validateEditProfile}
        enableReinitialize
        onSubmit={async (values, { setSubmitting }) => {
          await handleSubmit(values);
          setSubmitting(false);
        }}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Box margin={{ top: 'medium', bottom: 'medium' }}>
              <Box
                gap="small"
                direction="row"
                fill
                margin={{ bottom: 'small' }}
              >
                <Box basis="33%" position="relative">
                  {logo ? (
                    <WrapperLogo>
                      <CloseButton
                        hoverIndicator
                        icon={<FormClose />}
                        onClick={handleRemoveLogo}
                        size="large"
                        roundSmall
                      />
                      <LogoProfile logo={logoUrl || file} />
                    </WrapperLogo>
                  ) : (
                    <FilesUpload onUpload={logoUrl ? () => null : onUpload}>
                      <LogoProfile logo={logoUrl || file} />
                    </FilesUpload>
                  )}
                </Box>
                <Box basis="67%">
                  <TextInput
                    box={{
                      margin: {
                        bottom: 'small',
                      },
                    }}
                    name="name"
                    type="text"
                    placeholder="Company Name"
                    label="Company Name"
                  />
                  <TextInput
                    name="industry"
                    type="text"
                    placeholder="Industry"
                    label="Industry"
                  />
                </Box>
              </Box>

              <Box as="div" position="relative" margin={{ bottom: 'medium' }}>
                <Text middleLine="-42px" color="#666666" size="small">
                  Address
                </Text>
                <hr />
              </Box>
              <Box>
                <Box gap="small" direction="row" fill>
                  <TextInput
                    box={{
                      margin: {
                        bottom: 'small',
                      },
                      basis: '50%',
                    }}
                    name="address.city"
                    type="text"
                    placeholder="City Name"
                    label="City Name"
                  />
                  <TextInput
                    box={{
                      margin: {
                        bottom: 'small',
                      },
                      basis: '50%',
                    }}
                    name="address.street"
                    type="text"
                    placeholder="Street"
                    label="Street"
                  />
                </Box>
                <Box gap="small" direction="row" fill>
                  <SelectInput
                    box={{
                      margin: {
                        bottom: 'small',
                      },
                      basis: '33%',
                    }}
                    labelKey="text"
                    valueKey="value"
                    name="address.state"
                    options={states}
                    dropHeight="small"
                    placeholder="State"
                    label="State"
                  />
                  <TextInput
                    box={{
                      margin: {
                        bottom: 'small',
                      },
                      basis: '33%',
                    }}
                    name="address.country"
                    type="text"
                    placeholder="Country"
                    label="Country"
                  />
                  <TextInput
                    box={{
                      margin: {
                        bottom: 'small',
                      },
                      basis: '33%',
                    }}
                    name="address.zip"
                    type="text"
                    placeholder="Zip Code"
                    label="Zip Code"
                  />
                </Box>
              </Box>
            </Box>
            <Box
              direction="row"
              justify="end"
              gap="medium"
              margin={{ top: 'medium' }}
            >
              {isSubmitting ? (
                <Box align="center" direction="row" gap="small">
                  <Spinner
                    border={[
                      {
                        side: 'all',
                        color: 'black',
                        size: 'medium',
                        style: 'dotted',
                      },
                    ]}
                  />
                  <Text>Processing...</Text>
                </Box>
              ) : (
                <>
                  <Button
                    type="submit"
                    label="Submit"
                    size="large"
                    primary
                    roundSmall
                    disabled={isSubmitting || !isValid}
                  />
                </>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </PageWrapper>
  );
};
