export default {
  anchor: {
    hover: {
      textDecoration: 'none',
    },
    fontWeight: 400,
  },
  button: {
    extend: (props) => {
      let extraStyles = '';
      if (props.p) {
        extraStyles = `
            ${extraStyles}
            padding: ${props.p};
          `;
      }
      if (props.xsmall) {
        extraStyles = `
            ${extraStyles}
            font-size: 12px;
            line-height: 1.2;
          `;
      }
      if (props.round50) {
        extraStyles = `
            ${extraStyles}
            border-radius: 50%;
            padding: 8px;
          `;
      }
      if (props.large) {
        extraStyles = `
            ${extraStyles}
            font-size: 16px;
            line-height: 1.25;
          `;
      }
      if (props.primary) {
        extraStyles = `
            ${extraStyles}
            color: white;
          `;
      }
      if (props.roundSmall) {
        extraStyles = `
            ${extraStyles}
            border-radius: 4px;
          `;
      }
      if (props.outline) {
        extraStyles = `
            ${extraStyles}
            color: #2e2e2e;
            box-shadow: none !important;
          `;
      }
      if (props.notShadow) {
        extraStyles = `
            ${extraStyles}
            box-shadow: none !important;
          `;
      }
      if (props.outlineBrand) {
        extraStyles = `
            ${extraStyles}
            &:active,
            &:focus {
              border-color: #7797FF;
            }
          `;
      }
      return `
          font-size: 14px;
          font-weight: bold;
          outline: 0;
          &:active {
            border-color: #333333;
            box-shadow: none !important;
          }
          &:focus {
            border-color: #333333;
            box-shadow: none !important;
          }
          &:disabled {
            cursor: not-allowed
          }
          ${extraStyles}
        `;
    },
  },
};
