export default {
  layer: {
    background: 'white',
    border: {
      radius: '1rem',
    },
    container: {
      zIndex: '15',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.25)',
    },
    responsiveBreakpoint: 'small',
    zIndex: '10',
  },
};
