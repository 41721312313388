import { useState, useCallback, useEffect } from 'react';

import { useFirebaseApi } from 'shared/hooks';
import { normalizeCollection } from 'shared/utils';
import { TFBQuerySnapshot, TCollectionItem } from 'shared/types';

export function useGarages() {
  const [garages, setGarages] = useState<TCollectionItem[]>([]);

  const { dbQuery } = useFirebaseApi();

  const getGarages = useCallback(() => {
    const fetchGarages = async () => {
      try {
        const res = await dbQuery('garages').get();
        setGarages(normalizeCollection(res as TFBQuerySnapshot));
      } catch (e) {
        setGarages([]);
      }
    };
    fetchGarages();
  }, [dbQuery]);

  useEffect(() => {
    getGarages();
  }, [getGarages]);

  return {
    garages,
    fetchGarages: getGarages,
  };
}
