import { DataTable as DTable, Box, DataTableProps } from 'grommet';
import styled from 'styled-components';

const Table = styled(DTable)`
  width: 100%;
  white-space: nowrap;
  th > div > span {
    font-size: 16px;
    font-weight: 600;
  }
  td > div > span {
    font-size: 14px;
    font-weight: 400;
  }
  tbody {
    outline: 0;
    box-shadow: none;
  }
`;

export function DataTable(props: DataTableProps) {
  return (
    <Box pad="large" overflow="auto">
      <Table
        background={{
          body: 'white',
          header: 'light-1',
        }}
        border={{
          body: {
            color: '#F0EFEF',
            side: 'bottom',
          },
          header: {
            color: 'white',
          },
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
        primaryKey="key"
      />
    </Box>
  );
}
