import { createContext, useContext, ReactNode, useMemo } from 'react';
import 'firebase/auth';

import { TCollectionItem, IValidation } from 'shared/types';
import { useGarages } from './UseGarages';
import { useValidations } from './UseValidations';

export type TGlobalContextValues = {
  garages: TCollectionItem[];
  validations: IValidation[];
  getValidations: () => Promise<void>;
};

type ProviderProps = {
  children: ReactNode;
};

const GlobalContext = createContext<TGlobalContextValues>({
  garages: [],
  validations: [],
  getValidations: () => new Promise(() => {}),
});

const { Provider } = GlobalContext;

function GlobalContextProvider({ children }: ProviderProps): JSX.Element {
  const { garages } = useGarages();
  const { validations, getValidations } = useValidations();

  const value = useMemo(
    () => ({
      garages,
      validations,
      getValidations,
    }),
    [garages, getValidations, validations],
  );

  return <Provider value={value}>{children}</Provider>;
}

function useGlobalContext(): TGlobalContextValues {
  return useContext(GlobalContext);
}

export { GlobalContextProvider, GlobalContext, useGlobalContext };
