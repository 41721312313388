import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const activeBG = '#222222';

export const StyledLink = styled(NavLink)`
  display: block;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 1;
  background-color: transparent;
  transition-property: background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;
  margin: 0px;
  text-decoration: none;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  padding-left: 3.5rem;
  padding-right: 1rem;
  border-radius: 6px;
  &:hover {
    background-color: ${activeBG};
  }
  &.active {
    background-color: ${activeBG};
  }
  svg {
    width: 22px !important;
    height: 22px !important;
    transform: translateY(-1px);
  }
`;

export const IconLink = styled(NavLink)`
  display: block;
  position: relative;
  line-height: 1;
  background-color: transparent;
  transition-property: background-color;
  transition-timing-function: ease-out;
  transition-duration: 0.2s;
  margin: 0;
  text-decoration: none;
  padding: 1.25rem;
  border-radius: 29px;
  &:hover {
    svg {
      opacity: 1;
    }
  }
  &.active {
    background-color: rgba(255, 255, 255, 0.15);
    svg {
      opacity: 1;
    }
  }
  svg {
    width: 18px !important;
    height: 18px !important;
    opacity: 0.7;
  }
`;
