import { IEmployee } from 'shared/types';

const ADD_USER_MESSAGE = 'User is added to the team.';
const UPDATE_USER_MESSAGE = 'User is added to the team.';

export const getSetEmployeeMessage = (id: string | undefined) =>
  id ? UPDATE_USER_MESSAGE : ADD_USER_MESSAGE;

export const getRemoveEmployeeMessage = ({ firstName, lastName }: IEmployee) =>
  `${firstName} ${lastName} has been removed from the team.`;
