import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Text } from 'grommet';
import { Previous } from 'grommet-icons';

import { useGotoRoute, RouteName } from 'App/routes';
import { PageWrapper, ValidationCard } from 'shared';
import { useValidation } from './hooks';
import { Actions } from './components/Actions';

export function Validation() {
  const { validationId } = useParams<{ validationId: string }>();
  const { validation, fetchValidation } = useValidation({ validationId });
  const goBack = useGotoRoute(RouteName.Home);
  return validation?.validationCount ? (
    <PageWrapper>
      <Box align="start" justify="between">
        <Button
          label={<Text>View all validations</Text>}
          icon={<Previous color="black" />}
          gap="small"
          plain
          onClick={() => goBack()}
          disabled={false}
          size="large"
        />
      </Box>
      <Box margin={{ vertical: 'small' }} />
      <Box gap="medium">
        <ValidationCard validation={validation} onClick={() => {}} />
      </Box>

      <Actions validation={validation} onUpdate={fetchValidation} />
    </PageWrapper>
  ) : null;
}
