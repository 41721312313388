import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { useSession } from '../session';
import { authenticatedRoutes } from './helpers';
import { UnauthenticatedRoutes } from './UnauthenticatedRoutes';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';

export const AppRoutes = (): JSX.Element => {
  const session = useSession();

  if (session.initializing) {
    return <p>Loading...</p>;
  }

  return (
    <Router>
      <Switch>
        <Route
          path={authenticatedRoutes.map(({ path }) => path)}
          component={AuthenticatedRoutes}
        />
        <Route path="*" component={UnauthenticatedRoutes} />
      </Switch>
    </Router>
  );
};
