import { Box } from 'grommet';

import { IValidation } from 'shared/types';
import { useBusy } from 'shared/hooks';
import { Input } from './Input';
import { Success } from './Success';
import { useApplyValidation, ESteps } from '../../hooks';

type Props = {
  validation: IValidation;
  onClose: () => void;
};

export function ApplySend({ validation, onClose }: Props) {
  const { busy } = useBusy();
  const { id } = validation;
  const {
    submitIdentity,
    identity: { value, type },
    step,
  } = useApplyValidation({ validationId: id });
  return (
    <Box
      width="medium"
      background="white"
      pad={{ vertical: 'small', horizontal: 'large' }}
      round="xxsmall"
    >
      {step === ESteps.Input && <Input onSubmit={submitIdentity} busy={busy} />}
      {step === ESteps.Success && (
        <Success
          identity={value as string}
          onClick={onClose}
          type={type as string}
        />
      )}
    </Box>
  );
}
