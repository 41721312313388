import firebase from 'firebase/app';
import 'firebase/storage';
import { replace, map } from 'rambda';

export const storageBucket = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET;

export const getImageUrl = (image?: string) =>
  image &&
  `https://firebasestorage.googleapis.com/v0/b/${storageBucket}/o/${encodeURIComponent(
    replace('storage://', '', image),
  )}?alt=media`;

export const getPhotos = (photos: string[]) => map(getImageUrl, photos);

export const getDownloadUrl = async (file = '') =>
  file && firebase.storage().ref(file).getDownloadURL();
