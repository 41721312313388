import { useCallback, useEffect, useState } from 'react';
import { useBusy, useError, useApiFunction } from 'shared/hooks';
import { useApiQueryData } from 'shared/hooks/useQueryData';
import { IValidation, IValidationCount } from 'shared/types';

type ReturnType = {
  validation: IValidation | null;
  pending: boolean;
  fetchValidation: () => void;
};

export function useValidation({
  validationId,
}: {
  validationId: string;
}): ReturnType {
  const { setBusy } = useBusy();
  const [validation, setValidation] = useState<IValidation | null>(null);
  const { sponsor } = validation || {};
  const { id: sponsorId } = sponsor || {};
  const [validationCount, setValidationCount] =
    useState<IValidationCount | null>(null);
  const countValidationFn = useApiFunction('portal_validations_count');
  const { execute, apiData, pending, error } = useApiQueryData<IValidation>({
    path: `validations/${validationId}`,
    immediate: false,
  });

  useBusy(pending);
  useError(error);

  useEffect(() => {
    if (apiData) {
      setValidation({
        ...apiData,
      });
    }
  }, [apiData]);

  useEffect(() => {
    execute();
  }, [execute, validationId]);

  const getValidationCount = useCallback(async () => {
    try {
      setBusy(true);
      if (sponsorId && validationId) {
        const countValidation = await countValidationFn({
          sponsorId,
          validationId,
        });
        setValidationCount(countValidation);
      }
    } catch {
      setBusy(false);
    } finally {
      setBusy(false);
    }
  }, [countValidationFn, setBusy, sponsorId, validationId]);

  useEffect(() => {
    getValidationCount();
  }, [getValidationCount]);

  return {
    validation: { ...validation, validationCount } as IValidation,
    pending,
    fetchValidation: execute,
  };
}
