import { useRef, useState, useEffect } from 'react';

import {
  TFBQuerySnapshot,
  TFBDocumentSnapshot,
  TCollectionItem,
} from 'shared/types';
import { normalizeCollection, normalizeDoc } from 'shared/utils';
import { useAsync, useFirebaseApi } from '.';

type Params = {
  path: string;
  immediate?: boolean;
};

export function useApiQueryData<T>({ path, immediate = true }: Params) {
  const [apiData, setApiData] = useState<
    TCollectionItem[] | TFBDocumentSnapshot | null
  >(null);
  const { dbQuery } = useFirebaseApi();
  const asyncFunction = useRef(() => dbQuery(path).get()).current;

  const { result, ...rest } = useAsync<unknown>(asyncFunction, immediate);

  useEffect(() => {
    if (result) {
      if (typeof (result as TFBQuerySnapshot).size === 'number') {
        setApiData([
          ...normalizeCollection<TCollectionItem>(result as TFBQuerySnapshot),
        ]);
      } else if ((result as TFBDocumentSnapshot).exists) {
        setApiData(
          normalizeDoc(result as TFBDocumentSnapshot) as TFBDocumentSnapshot,
        );
      } else setApiData(null);
    }
  }, [result]);

  return { apiData: apiData as unknown as T, result, ...rest };
}
