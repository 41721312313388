import { Text } from 'grommet';
import { keys } from 'rambda';

interface Props {
  error: string | { [key: string]: string };
}

export function ErrorOutput({ error }: Props) {
  if (typeof error === 'string') {
    return (
      <Text color="status-error" size="xsmall">
        {error}
      </Text>
    );
  }

  return (
    <>
      {keys(error).map((key) => (
        <Text color="status-error" key={key} size="xsmall">
          {error[key]}
        </Text>
      ))}
    </>
  );
}
