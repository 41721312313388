import React from 'react';
import { Button, Text } from 'grommet';
import { Edit, FormTrash } from 'grommet-icons';

import { IEmployee } from 'shared/types';

type ActionFn = (id: string) => void;

type EditParams = {
  id: string;
  handleEdit: ActionFn;
};

type DeleteParams = {
  id: string;
  handleDelete: ActionFn;
};

type TData = IEmployee & {
  handleEdit: ActionFn;
  handleDelete: ActionFn;
};

const renderName = (firstName: string, lastName: string) => (
  <Text>{`${firstName} ${lastName}`}</Text>
);

export const tableHeader = [
  {
    property: 'firstName',
    header: 'Name',
    render: ({
      firstName,
      lastName,
    }: {
      firstName: string;
      lastName: string;
    }) => renderName(firstName, lastName),
  },
  {
    property: 'phone',
    header: 'Phone',
  },
  {
    property: 'email',
    header: 'Email',
  },
  {
    property: 'edit',
    header: '',
    render: ({ id, handleEdit }: EditParams) => (
      <Button
        label="Edit"
        gap="xsmall"
        roundSmall
        plain
        icon={<Edit size="medium" />}
        onClick={() => handleEdit(id)}
      />
    ),
  },
  {
    property: 'delete',
    header: '',
    render: ({ id, handleDelete }: DeleteParams) => (
      <Button
        label="Delete"
        gap="xsmall"
        roundSmall
        plain
        icon={<FormTrash size="medium" />}
        onClick={() => handleDelete(id)}
      />
    ),
  },
];

export const formattedTableData = (data: TData[]) => ({
  columns: tableHeader,
  DATA: data,
});
