import { useCallback, useState } from 'react';
import { replace } from 'rambda';
import {
  useApiFunction,
  useToast,
  useBusy,
  useGetSponsorData,
  getErrorMessage,
} from 'shared';
import { TApplySendInput } from 'shared/validations';

export enum ESteps {
  Input = 'input',
  Prompt = 'prompt',
  Success = 'success',
}

type TIdentity = { type?: 'park' | 'phone'; value?: string };

interface ReturnValue {
  submitIdentity: (v: TApplySendInput) => Promise<boolean>;
  identity: TIdentity;
  step: ESteps;
}

export function useApplyValidation({
  validationId,
}: {
  validationId: string;
}): ReturnValue {
  const toast = useToast();
  const { setBusy } = useBusy();
  const { getSponsor } = useGetSponsorData();
  const [identity, setIdentity] = useState<TIdentity>({});
  const [step, setStep] = useState<ESteps>(ESteps.Input);

  const linkToSessionFn = useApiFunction('portal_validations_link_to_session');
  const linkToUserFn = useApiFunction('portal_validations_link_to_user');

  const linkSession = useCallback(
    async (parkId: string) => {
      const { sponsorId } = await getSponsor();
      return linkToSessionFn({
        sponsorId,
        validationId,
        overrideId: parkId,
      });
    },
    [getSponsor, linkToSessionFn, validationId],
  );

  const linkToUser = useCallback(
    async (phoneNumber: string) => {
      const { sponsorId } = await getSponsor();
      return linkToUserFn({
        sponsorId,
        validationId,
        phone: replace(/[ ,(,),-]/g, '', phoneNumber),
      });
    },
    [getSponsor, linkToUserFn, validationId],
  );

  const submitIdentity = useCallback(
    async ({ phone, parkId, type }: TApplySendInput) => {
      try {
        setBusy(true);
        if (type === 'park') {
          await linkSession(parkId as string);
          setIdentity({ value: parkId, type: 'park' } as TIdentity);
        } else {
          await linkToUser(phone as string);
          setIdentity({ value: phone, type: 'phone' } as TIdentity);
        }
        setStep(ESteps.Success);
        return true;
      } catch (error) {
        if (error instanceof Error) {
          toast.error(getErrorMessage(error, "Couldn't apply validation"));
        }
        return false;
      } finally {
        setBusy(false);
      }
    },
    [linkSession, linkToUser, setBusy, toast],
  );

  return {
    submitIdentity,
    identity,
    step,
  };
}
