import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useCallback,
  useEffect,
} from 'react';
import 'firebase/auth';

import { TUser } from 'shared/types';
import * as sessionStore from './PersistentStorage';
import { useFirebase } from '../Firebase';

const defaultToken = sessionStore.get('refresh_token') || null;

export type TSessionContextValues = {
  initializing: boolean;
  authenticated: boolean;
  deauthenticated: boolean;
  token: string | null;
  user: TUser | null;
  authenticate: (user: TUser) => void;
  signout: () => void;
  setInitializing: () => void;
  sponsorId?: string;
};

type SessionProviderProps = {
  children: ReactNode;
};

const SessionContext = createContext<TSessionContextValues>({
  initializing: true,
  authenticated: false,
  deauthenticated: false,
  token: null,
  user: null,
  authenticate: () => {},
  signout: () => {},
  setInitializing: () => {},
});

const { Provider } = SessionContext;

enum SessionStatus {
  Initializing = 'Initializing',
  Authenticated = 'Authenticated',
  Deauthenticated = 'Deauthenticated',
}

function SessionProvider({ children }: SessionProviderProps): JSX.Element {
  const firebase = useFirebase();
  const [status, setStatus] = useState<SessionStatus>(
    SessionStatus.Initializing,
  );
  const [user, setUser] = useState<TUser | null>(null);
  const [token, setToken] = useState(defaultToken);

  function authenticate(userData: TUser): void {
    setUser(userData);
    setToken(userData.token);
    sessionStore.set({ refresh_token: userData.refreshToken });
    setStatus(SessionStatus.Authenticated);
  }

  function deauthenticate(): void {
    setUser(null);
    setToken(null);
    sessionStore.clear();
    setStatus(SessionStatus.Deauthenticated);
  }

  const signout = useCallback(() => {
    return firebase
      .auth()
      .signOut()
      .then(() => {
        deauthenticate();
      });
  }, [firebase]);

  function setInitializing(): void {
    setStatus(SessionStatus.Initializing);
  }

  function inStatus(s: SessionStatus): boolean {
    return status === s;
  }

  useEffect(() => {
    const auth = firebase.auth();
    const checkUser = async (userData: unknown) => {
      if (userData) {
        const tokenUser = await auth.currentUser?.getIdTokenResult();
        if (!tokenUser) {
          signout();
        } else {
          authenticate(userData as TUser);
        }
      } else {
        signout();
      }
    };

    const unsubscribe = auth.onAuthStateChanged(checkUser);

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [firebase, signout]);

  const value: TSessionContextValues = {
    initializing: inStatus(SessionStatus.Initializing),
    authenticated: inStatus(SessionStatus.Authenticated),
    deauthenticated: inStatus(SessionStatus.Deauthenticated),
    token,
    user,
    authenticate,
    signout,
    setInitializing,
    sponsorId: user?.uid,
  };

  return <Provider value={value}>{children}</Provider>;
}

function useSession(): TSessionContextValues {
  return useContext(SessionContext);
}

export { SessionProvider, SessionContext, useSession };
