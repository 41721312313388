import { Box, Button, Text } from 'grommet';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { TextInput } from 'shared/controls';

import { useResetPassword } from '../hooks';

interface Props {
  code: string;
  goTo: string;
}

export function ChangePassword(props: Props) {
  const { code, goTo } = props;
  const { resetPassword } = useResetPassword();

  return (
    <Formik
      initialValues={{
        password: '',
        confirmPassword: '',
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(6, 'Must be 6 characters or more')
          .required('Required'),
        confirmPassword: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Passwords must match',
        ),
      })}
      onSubmit={async ({ password }, { setSubmitting }) => {
        const res = await resetPassword(code, password);
        setSubmitting(false);
        if (res) {
          window.location.href = goTo;
        }
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Text
            as="h2"
            size="large"
            margin={{
              top: 'none',
              bottom: 'small',
            }}
          >
            Please, Enter your password
          </Text>
          <Box>
            <TextInput
              box={{
                margin: {
                  bottom: 'small',
                },
              }}
              name="password"
              type="password"
              placeholder="New Password"
            />
            <TextInput
              box={{
                margin: {
                  bottom: 'small',
                },
              }}
              name="confirmPassword"
              type="password"
              placeholder="Confirm New Password"
            />
            <Button
              primary
              fill
              label="Submit"
              size="large"
              type="submit"
              roundSmall
              disabled={isSubmitting || !isValid}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
