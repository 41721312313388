import { equals, drop } from 'rambda';

import { TValidation as CoreValidation } from 'shared/types';
import { ETypeValues } from 'shared/constants/validationTypes';
import { TValidation } from 'shared/validations';
import { getMilisFromString } from 'shared/utils';

const getValidationValue = ({
  type,
  dollarValue,
  percentageValue,
  hoursValue,
}: TValidation) => {
  if (equals(ETypeValues.Dollar, type)) {
    return +drop(2, dollarValue as string) * 100;
  }

  if (equals(ETypeValues.TimeBased, type)) {
    return getMilisFromString(hoursValue as string);
  }

  return +(percentageValue as number);
};

const getValidationPayload = (
  data: TValidation,
): Omit<CoreValidation, 'status' | 'sponsor'> => {
  const { type, dollarValue, percentageValue, hoursValue, basePrice, ...rest } =
    data;
  return {
    ...rest,
    type,
    value: getValidationValue(data),
  };
};
export { getValidationPayload };
