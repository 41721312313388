import { Link } from 'react-router-dom';
import { Box, Button, Text } from 'grommet';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { RouteName, generateRoutePath } from 'App/routes';
import { TextInput } from 'shared/controls';
import { useResetPassword } from '../hooks';

export function RequestToChangePassword() {
  const { resetPasswordWithEmail } = useResetPassword();
  return (
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object({
        email: Yup.string().email('Invalid email address').required('Required'),
      })}
      onSubmit={async ({ email }, { setSubmitting }) => {
        await resetPasswordWithEmail(email);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form>
          <Text
            as="h2"
            size="large"
            margin={{
              top: 'none',
              bottom: 'small',
            }}
          >
            Please, Enter your email
          </Text>
          <Text as="p" size="small">
            Login?{' '}
            <Link to={generateRoutePath({ name: RouteName.SignIn })}>
              Sing In
            </Link>
          </Text>
          <Box>
            <TextInput
              box={{
                margin: {
                  bottom: 'small',
                },
              }}
              name="email"
              type="text"
              placeholder="Email"
            />
            <Button
              primary
              fill
              label="Submit"
              size="large"
              type="submit"
              roundSmall
              disabled={isSubmitting || !isValid}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
}
