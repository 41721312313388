import { Box, Button, Text } from 'grommet';
import { Formik, Form } from 'formik';
import PhoneInput from 'react-phone-number-input';

import { RadioButtonGroup, MaskedInput } from 'shared/controls';
import { parkId } from 'shared/constants/masks';
import { applySendScheme, TApplySendInput } from 'shared/validations';

type Props = {
  onSubmit: (v: TApplySendInput) => void;
  busy: boolean;
};

const label: { [key: string]: string } = {
  park: 'Find session',
  phone: 'Send code',
};

export function Input({ onSubmit, busy }: Props) {
  return (
    <Box fill animation="fadeIn">
      <Formik
        initialValues={{
          type: 'park',
          phone: '',
          parkId: '',
        }}
        validationSchema={applySendScheme}
        onSubmit={async (values, { setSubmitting }) => {
          onSubmit(values as TApplySendInput);
          setSubmitting(false);
        }}
      >
        {({ isValid, values, setFieldValue }) => (
          <Form>
            <Text
              as="p"
              size="xsmall"
              margin={{
                top: 'none',
                bottom: 'xsmall',
              }}
              weight="bold"
            >
              Enter park ID or phone number
            </Text>
            <Box>
              <RadioButtonGroup
                options={[
                  { label: 'Park Id', value: 'park' },
                  { label: 'Phone Number', value: 'phone' },
                ]}
                box={{
                  margin: { bottom: 'xsmall' },
                }}
                name="type"
                value={values.type}
                handleChange={(v) => {
                  setFieldValue('type', v);
                }}
              />
              <Box fill margin={{ bottom: 'small' }}>
                {values.type === 'phone' ? (
                  <PhoneInput
                    defaultCountry="US"
                    country="US"
                    onChange={(v: string) => setFieldValue('phone', v)}
                    placeholder="Enter phone number"
                    value={values.phone}
                  />
                ) : (
                  <MaskedInput mask={parkId} name="parkId" />
                )}
              </Box>

              <Box fill align="start">
                <Button
                  primary
                  color="brand"
                  label={busy ? 'Processing...' : label[values.type]}
                  size="large"
                  type="submit"
                  disabled={!isValid || busy}
                  roundSmall
                />
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
