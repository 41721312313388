/* eslint-disable no-console */
import { Link } from 'react-router-dom';
import { Box, Button, Text } from 'grommet';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import { RouteName, generateRoutePath } from 'App/routes';
import { TextInput } from 'shared/controls';
import { useSignin } from './hooks';

export function Signin() {
  const { signin } = useSignin();
  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      validationSchema={Yup.object({
        password: Yup.string()
          .min(6, 'Must be 6 characters or more')
          .required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
      })}
      onSubmit={async ({ email, password }, { setSubmitting }) => {
        setSubmitting(false);
        await signin(email, password);
      }}
    >
      <Form>
        <Text
          as="h2"
          size="large"
          margin={{
            top: 'none',
            bottom: 'none',
          }}
        >
          Please, Sign In
        </Text>
        <Text as="p" size="small">
          Do not have an account?{' '}
          <Link to={generateRoutePath({ name: RouteName.SignUp })}>
            Sign Up
          </Link>
        </Text>
        <Box>
          <TextInput
            box={{
              margin: {
                bottom: 'small',
              },
            }}
            name="email"
            type="text"
            placeholder="Email"
          />
          <TextInput
            box={{
              margin: {
                bottom: 'small',
              },
            }}
            name="password"
            type="password"
            placeholder="Password"
          />
          <Box pad={{ vertical: 'xxsmall' }}>
            <Link to={generateRoutePath({ name: RouteName.ResetPass })}>
              Forgot Password?
            </Link>
          </Box>
          <Button
            primary
            fill
            label="Sign In"
            size="large"
            type="submit"
            roundSmall
          />
        </Box>
      </Form>
    </Formik>
  );
}
