/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Box,
  Text,
  TextInput as Input,
  BoxExtendedProps,
  TextInputProps,
} from 'grommet';
import { useField, useFormikContext } from 'formik';

interface Props extends Omit<TextInputProps, 'onSelect'> {
  label?: string;
  sizeTextError?: string;
  box?: BoxExtendedProps;
  noPadding?: boolean;
  placeholder?: string;
}

export function TextInput(props: Props) {
  const {
    label,
    sizeTextError = 'xsmall',
    box,
    name,
    size,
    noPadding,
    placeholder,
    ...rest
  } = props;
  const [field, meta] = useField(name as string);
  const { setFieldValue } = useFormikContext();
  const { touched, error } = meta;

  const onSuggestionSelect = (e: {
    suggestion: string | Record<string, string | number>;
  }) => {
    setFieldValue(field.name, e.suggestion);
  };
  return (
    <Box gap="xxsmall" {...(box || {})}>
      <Input
        {...field}
        {...rest}
        noPadding={noPadding}
        onSuggestionSelect={onSuggestionSelect}
        hasError={touched && error}
        placeholder={placeholder}
      />
      {touched && error ? (
        <Text wordBreak="keep-all" color="status-error" size={sizeTextError}>
          {error}
        </Text>
      ) : (
        label && (
          <Text color="light-5" bold size="xsmall">
            {label}
          </Text>
        )
      )}
    </Box>
  );
}
