import { Box, Layer, Button, Text } from 'grommet';

import { PageWrapper, PageHeader, DataTable, SearchInput } from 'shared';
import { useEmployees, useEmployeesList, ModalType } from './hooks';
import { AddUser, UpdateUser, RemoveUser } from './components';

export function Team() {
  const { employees, fetchEmployees } = useEmployees();
  const {
    open,
    onClose,
    data,
    onUpdate,
    setPattern,
    modalType,
    selectedEmployee,
    handleAdd,
  } = useEmployeesList({ employees, fetchEmployees });
  return (
    <>
      <PageWrapper>
        <Box align="end">
          <Button
            label={<Text>+ Add Team Member</Text>}
            gap="small"
            primary
            roundSmall
            onClick={handleAdd}
            disabled={false}
            size="large"
          />
        </Box>
        <PageHeader title="My Team" />
        <Box margin={{ vertical: 'small' }} />
        <Box align="center">
          <SearchInput onChange={setPattern} placeholder="Search Employee" />
        </Box>

        <Box gap="medium">
          <DataTable data={data.DATA} columns={data.columns} />
        </Box>
      </PageWrapper>
      {open && (
        <Layer
          position="center"
          animation="fadeIn"
          modal
          onClickOutside={onClose}
          onEsc={onClose}
        >
          {modalType === ModalType.ADD && (
            <AddUser onClose={onClose} onUpdate={onUpdate} />
          )}
          {modalType === ModalType.UPDATE && (
            <UpdateUser
              onClose={onClose}
              onUpdate={onUpdate}
              employee={selectedEmployee}
            />
          )}
          {modalType === ModalType.DELETE && (
            <RemoveUser
              onClose={onClose}
              onRemove={onUpdate}
              employee={selectedEmployee}
            />
          )}
        </Layer>
      )}
    </>
  );
}
