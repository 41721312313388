/* eslint-disable prettier/prettier */
import { TCollectionItem, TFDocumentRef } from './firebase';

export type TUser = {
  uid: string;
  token: string;
  refreshToken: string;
  email: string;
  displayName: string;
};

export type TCustomRate = {
  time: number;
  priceIncrease: number;
};

type TSponsor = {
  id: string;
};

export interface IValidationCount {
  data: {
    validations_count: number;
  };
}

export type TValidation = {
  status: EValidationStatus;
  type: string;
  description: string;
  value: number | TCustomRate[];
  garage: string;
  sponsor: TSponsor;
  validationCount?: IValidationCount;
};

export interface IValidation
  extends Omit<TValidation, 'garage'>,
  TCollectionItem {
  garage: TFDocumentRef;
  url: string;
}

export enum EValidationStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  READY_TO_ACTIVATE = 'ready-to-activate',
  AWAITING_CONFIRMATION = 'awaiting-confirmation',
}

export interface IEmployee extends TCollectionItem {
  phone: string;
  firstName: string;
  lastName: string;
  email: string;
}

export type TEmployeeFormValues = Omit<IEmployee, 'id' | 'name'>;

export interface IFileContent {
  fileName: string;
  contentType: string;
  uploadContent: string;
  preview: string;
}
