import { useCallback } from 'react';

import {
  useGetSponsorData,
  useBusy,
  useApiFunction,
  useToast,
  getErrorMessage,
  DEFAULT_ERROR_MESSAGE,
} from 'shared';
import { TEmployee } from 'shared/validations';
import { IEmployee } from 'shared/types';
import { getSetEmployeeMessage, getRemoveEmployeeMessage } from '../utils';

export function useSetEmployee({
  onSuccess,
  employeeId,
}: {
  onSuccess: () => void;
  employeeId?: string;
}) {
  const { getSponsor } = useGetSponsorData();
  const { setBusy, busy } = useBusy();
  const toast = useToast();
  const setEmployeeFn = useApiFunction('portal_sponsors_employees_set_one');
  const removeEmployeeFn = useApiFunction(
    'portal_sponsors_employees_remove_one',
  );

  const setEmployee = useCallback(
    async (data: TEmployee) => {
      const { firstName, lastName, phone, email } = data;
      try {
        setBusy(true);
        const { sponsorId } = await getSponsor();
        const res = await setEmployeeFn({
          sponsorId,
          ...(employeeId && { employeeId }),
          data: {
            firstName,
            lastName,
            phone,
            email,
          },
        });
        if (res) {
          toast.success(getSetEmployeeMessage(employeeId));
          onSuccess();
        }
      } catch (e) {
        if (e instanceof Error) {
          toast.error(getErrorMessage(e, DEFAULT_ERROR_MESSAGE));
        }
      } finally {
        setBusy(false);
      }
    },
    [employeeId, getSponsor, onSuccess, setBusy, setEmployeeFn, toast],
  );

  const removeEmployee = useCallback(
    async (data: IEmployee) => {
      try {
        setBusy(true);
        const { sponsorId } = await getSponsor();
        const res = await removeEmployeeFn({
          sponsorId,
          employeeId,
        });
        if (res) {
          toast.success(getRemoveEmployeeMessage(data));
          onSuccess();
        }
      } catch (e) {
        if (e instanceof Error) {
          toast.error(getErrorMessage(e, DEFAULT_ERROR_MESSAGE));
        }
      } finally {
        setBusy(false);
      }
    },
    [employeeId, getSponsor, onSuccess, removeEmployeeFn, setBusy, toast],
  );

  return {
    setEmployee,
    removeEmployee,
    busy,
  };
}
