import { useGlobalContext } from 'App/globalContext';
import { useCallback } from 'react';
import { useApiFunction, useToast, useGetSponsorData, useBusy } from 'shared';
import { EValidationStatus } from 'shared/types';

export type TChangeStatus = (
  status: EValidationStatus,
  validationId: string,
) => Promise<boolean>;

export interface StatusReturnValue {
  changeStatus: TChangeStatus;
}

export function useValidationStatus({
  onSuccess = () => {},
}: {
  onSuccess?: () => void;
}): StatusReturnValue {
  const { getValidations } = useGlobalContext();
  const { getSponsor } = useGetSponsorData();
  const toast = useToast();
  const { setBusy } = useBusy();
  const updateStatusFn = useApiFunction('portal_validations_change_status');

  const changeStatus = useCallback(
    async (status: EValidationStatus, validationId: string) => {
      try {
        setBusy(true);
        const { sponsorId } = await getSponsor();
        await updateStatusFn({
          sponsorId,
          validationId,
          status,
        });
        onSuccess();
        getValidations();
        return true;
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
        return false;
      } finally {
        setBusy(false);
      }
    },
    [getSponsor, getValidations, onSuccess, setBusy, toast, updateStatusFn],
  );

  return {
    changeStatus,
  };
}
