import { ReactNode } from 'react';
import { Grid, Main } from 'grommet';

import { Loader } from './TopBarProgress';
import { BusyProvider } from './busy/BusyContext';
import { useProvideBusy } from './busy/UseProvideBusy';
import { Sidebar } from './components';

export function Layout({ children }: { children: ReactNode }) {
  const { busy, setBusy } = useProvideBusy();
  return (
    <BusyProvider value={{ busy, setBusy }}>
      <Grid
        fill
        rows={['flex']}
        columns={['auto', 'flex']}
        areas={[
          { name: 'sidebar', start: [0, 0], end: [0, 0] },
          { name: 'main', start: [1, 0], end: [1, 0] },
        ]}
      >
        <Sidebar />
        <Main
          gridArea="main"
          overflow={{
            vertical: 'auto',
            horizontal: 'hidden',
          }}
        >
          {busy && <Loader />}
          <div>{children}</div>
        </Main>
      </Grid>
    </BusyProvider>
  );
}
