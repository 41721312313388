import React from 'react';
import { Box } from 'grommet';

export function PageWrapper({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element {
  return (
    <Box animation={['fadeIn', 'slideDown']} pad="large">
      {children}
    </Box>
  );
}
