import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from 'grommet';

export function Layout({
  children,
  width = 'medium',
}: {
  children: React.ReactNode;
  width?: string | 'medium' | 'large' | 'xlarge';
}): JSX.Element {
  const { pathname, hash } = useLocation();

  const main = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (main.current && !hash) {
      main.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname, hash]);

  return (
    <Box fill className="scroll-dark">
      <Box pad="large" align="center" width="full">
        <div ref={main} style={{ maxWidth: '100%', margin: '0 auto' }}>
          <Box
            pad="medium"
            elevation="medium"
            width={width}
            round="small"
            background="white"
            margin={{ bottom: 'large' }}
          >
            {children}
          </Box>
        </div>
      </Box>
    </Box>
  );
}
