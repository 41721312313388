import { useState, useCallback, useEffect } from 'react';
import { propOr } from 'rambda';

import { DEFAULT_ERROR_MESSAGE } from '../constants/errorMesages';

const getErrorMessage = (error: { message?: string }): string =>
  propOr(DEFAULT_ERROR_MESSAGE, 'message', error);

export function useAsync<T>(asyncFunction: () => Promise<T>, immediate = true) {
  const [pending, setPending] = useState(false);
  const [result, setResult] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);

  const execute = useCallback(() => {
    setPending(true);
    setResult(null);
    setError(null);

    return asyncFunction()
      .then((response) => setResult(response))
      .catch((err) => setError(getErrorMessage(err)))
      .finally(() => setPending(false));
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate && asyncFunction) {
      execute();
    }
  }, [asyncFunction, execute, immediate]);

  return { execute, pending, result, error };
}
