import React from 'react';
import { Box, Button, Text } from 'grommet';
import { Validate } from 'grommet-icons';

export function Success({
  identity,
  onClick = () => {},
  type,
}: {
  identity: string;
  onClick?: () => void;
  type: string;
}) {
  const action = type === 'park' ? 'applied' : 'sent';
  return (
    <Box fill animation="fadeIn">
      <Box
        direction="row"
        align="start"
        gap="xxsmall"
        margin={{ bottom: 'small' }}
      >
        <Validate color="brand" size="large" />
        <Box>
          <Text as="p" size="xsmall" margin="none">
            {`Validation was successfully ${action} to:`}
          </Text>
          <Text
            as="p"
            size="small"
            margin={{
              top: 'xsmall',
              bottom: 'none',
            }}
            weight="bold"
          >
            {identity || '222555'}
          </Text>
        </Box>
      </Box>

      <Box fill align="start">
        <Button
          color="black"
          label="Done"
          size="large"
          roundSmall
          onClick={onClick}
        />
      </Box>
    </Box>
  );
}
