import { Box } from 'grommet';
import { useURIQuery } from 'shared/hooks';
import { ChangePassword, RequestToChangePassword } from './components';

export function ResetPassword() {
  const query = useURIQuery();
  const code = query.get('code');
  const goTo = query.get('continue');

  return (
    <Box>
      {code && goTo ? (
        <ChangePassword code={code} goTo={goTo} />
      ) : (
        <RequestToChangePassword />
      )}
    </Box>
  );
}
