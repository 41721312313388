import { useRef } from 'react';

import { TFunction } from 'shared/types';
import { useFirebaseApi } from './useFirebaseApi';

export const useApiFunction = (fnStr: string): TFunction => {
  const { apiFunction } = useFirebaseApi();
  const apiFn = useRef(apiFunction(fnStr));

  return apiFn.current;
};
