import { useCallback, useState } from 'react';
import 'firebase/auth';

import { useFirebaseApi } from 'shared/hooks';
import { TDocumentData, TFBCollection, TFBDocument } from 'shared/types';
import { normalizeDoc } from 'shared/utils';
import { useFirebase } from 'App/Firebase';

export function useGetSponsorData() {
  const firebase = useFirebase();
  const { dbQuery } = useFirebaseApi();
  const [sponsorData, setSponsorData] = useState<TDocumentData>();

  const getSponsor = useCallback(async () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const token = await firebase.auth().currentUser!.getIdTokenResult();
    const { sponsor } = token.claims;

    const sponsorRef = await (dbQuery('sponsors') as TFBCollection).doc(
      sponsor,
    );
    return {
      sponsorRef,
      sponsorId: sponsorRef.id,
    };
  }, [dbQuery, firebase]);

  const getSponsorData = useCallback(async () => {
    const { sponsorRef } = await getSponsor();
    await (dbQuery(sponsorRef.path) as TFBDocument).onSnapshot((doc) => {
      setSponsorData(normalizeDoc(doc));
    });
  }, [dbQuery, getSponsor, setSponsorData]);

  return {
    getSponsor,
    getSponsorData,
    sponsorData,
  };
}
