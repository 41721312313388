import { useState } from 'react';

type ReturnValue = {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
};

export function useOpenClose(): ReturnValue {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const onOpen = () => setOpen(true);

  return { open, onOpen, onClose };
}
