import { useContext, useEffect } from 'react';

import BusyContext from 'layout/authenticated/busy/BusyContext';

export const useBusy = (loading = false) => {
  const { busy, setBusy } = useContext(BusyContext);

  useEffect(() => {
    setBusy(loading);
  }, [loading, setBusy]);

  return { busy, setBusy };
};
