import * as Yup from 'yup';

export const validateEditProfile = Yup.object({
  name: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .required('Required'),
  industry: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .required('Required'),
  address: Yup.object({
    zip: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.object().required('Required'),
    country: Yup.string().required('Required'),
    street: Yup.string().required('Required'),
  }),
});
