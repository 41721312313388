import * as Yup from 'yup';

import { requiredString, phoneNumberValidator } from './common';

export const employeeSchema = Yup.object({
  firstName: requiredString('Enter first name'),
  lastName: requiredString('Enter last name'),
  phone: phoneNumberValidator,
  email: Yup.string().required('Enter email').email('Invalid email'),
});

export type TEmployee = Yup.InferType<typeof employeeSchema>;
