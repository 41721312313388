export enum ETypeValues {
  Dollar = 'dollar',
  TimeBased = 'hours',
  Percentage = 'percentage',
}

export default [
  {
    text: 'Dollar',
    value: ETypeValues.Dollar,
  },
  {
    text: 'Time-based',
    value: ETypeValues.TimeBased,
  },
  {
    text: 'Percentage',
    value: ETypeValues.Percentage,
  },
];
