import { useFirebase } from 'App/Firebase';
import { useCallback } from 'react';
import { useApiFunction, useToast } from 'shared';

export function useResetPassword() {
  const firebase = useFirebase();

  const toast = useToast();
  const resetPasswordWithEmailFn = useApiFunction(
    'portal_sponsors_employees_reset_password',
  );
  const resetPassword = useCallback(
    async (code, password) => {
      const auth = firebase.auth();
      try {
        await auth.confirmPasswordReset(code, password);
        return true;
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
        return false;
      }
    },
    [firebase, toast],
  );

  const resetPasswordWithEmail = useCallback(
    async (email) => {
      try {
        await resetPasswordWithEmailFn({
          email,
        });
        toast.success('Message has been sent');
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
      }
    },
    [resetPasswordWithEmailFn, toast],
  );

  return {
    resetPasswordWithEmail,
    resetPassword,
  };
}
