import { useState, useCallback, useEffect } from 'react';
import 'firebase/auth';
import {
  useFirebaseApi,
  useBusy,
  useGetSponsorData,
  useApiFunction,
} from 'shared/hooks';
import { normalizeCollection } from 'shared/utils';
import { TFBCollection, IValidation, IValidationCount } from 'shared/types';

export function useValidations() {
  const { setBusy } = useBusy();
  const { getSponsor } = useGetSponsorData();
  const [validations, setValidations] = useState<IValidation[]>([]);
  const [validationsCount, setValidationCount] = useState<
    IValidationCount[] | null
  >(null);
  const countValidationFn = useApiFunction('portal_validations_count');
  const { dbQuery } = useFirebaseApi();

  const getValidations = useCallback(async () => {
    try {
      setBusy(true);

      const { sponsorRef } = await getSponsor();

      const res = await (dbQuery('validations') as TFBCollection)
        .where('sponsor', '==', sponsorRef)
        .get();

      if (res && res.size) {
        setValidations(normalizeCollection<IValidation>(res));
      }
    } catch (e) {
      setValidations([]);
    } finally {
      setBusy(false);
    }
  }, [dbQuery, getSponsor, setBusy]);

  useEffect(() => {
    getValidations();
  }, [getValidations]);

  const getValidationCount = useCallback(async () => {
    try {
      setBusy(true);

      const countValidation = await Promise.all(
        validations.map(({ sponsor: { id: sponsorId }, id: validationId }) =>
          countValidationFn({ sponsorId, validationId }),
        ),
      );
      setValidationCount(countValidation);
    } catch {
      setBusy(false);
    } finally {
      setBusy(false);
    }
  }, [countValidationFn, setBusy, validations]);

  useEffect(() => {
    getValidationCount();
  }, [getValidationCount]);

  return {
    validations: validations.map((v, i) => ({
      ...v,
      validationCount: validationsCount?.[i],
    })),
    getValidations,
  };
}
