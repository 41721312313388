import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html, body, #root {
    height: 100%;
    width: 100%;
    display: block;
  }

  hr {
    width: 100%;
    height: 1px;
    border: 1px solid #EDEDED;
  }

  .scroll-light {
    overflow-y: auto;
  }

  .RootNav {
    height: 100%;
    width: auto;
    min-width: 250px;
    transition: width .5s;
  }

  .RootNav.narrow {
    min-width: 100px;
  }

  .RootNav .expandable {
    padding: 0 21px 0 21px;
  }

  .RootNav .expandable .hideble {
    display: inline-block;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    overflow: hidden;
    color: white;
    max-width: 0;
    white-space: nowrap;
    transition: max-width .5s, linear .5s, padding .5s;
    padding-left: 0;
  }

  .RootNav:hover .expandable .hideble {
    max-width: 140px;
    padding-left: 16px;
  }

  .scroll-light::-webkit-scrollbar-track {
    border-radius   : 2px;
    background-color: rgba(255,255,255, .3);
  }

  .scroll-light::-webkit-scrollbar {
    width           : 6px;
    background-color: transparent;
  }

  .scroll-light::-webkit-scrollbar-thumb {
    border-radius   : 2px;
    background-color: #777;
  }

  .scroll-dark {
  overflow-y: auto;
  }

  .scroll-dark::-webkit-scrollbar-track {
    border-radius   : 2px;
    background-color: rgba(0,0,0, .25);
  }

  .scroll-dark::-webkit-scrollbar {
    width           : 6px;
    background-color: transparent;
  }

  .scroll-dark::-webkit-scrollbar-thumb {
    border-radius   : 2px;
    background-color: #fefefe;
  }
  button {
    cursor: pointer;
  }

  .PhoneInputInput{
    box-sizing: border-box;
    font-size: inherit;
    font-family: inherit;
    border: none;
    -webkit-appearance: none;
    background: transparent;
    color: inherit;
    width: 100%;
    padding: 11px;
    font-weight: 600;
    margin: 0;
    border: 1px solid rgba(0,0,0,0.33);
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    outline: 0;
    padding: 12px 20px;
    border-color: #e9e9e9;
    color: #2e2e2e;
    background-color: #e9e9e9;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }
`;

export default GlobalStyle;
