import { Button } from 'grommet';
import styled from 'styled-components';

export const WrapperLogo = styled.div`
  flex: 1;
  flex-grow: 3;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-width: 2px;
  border-radius: 8px;
  border-color: #eeeeee;
  border-style: solid;
  background-color: #f3f3f3;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
`;

export const CloseButton = styled(Button)`
  background: #fff;
  padding: 5px;
  position: absolute;
  top: 3px;
  right: 3px;
`;
