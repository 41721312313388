import { generatePath, useHistory } from 'react-router-dom';
import { ComponentType } from 'react';
import { IconProps, StatusGood, Group } from 'grommet-icons';

import {
  Home as HomePage,
  Signin as SigninPage,
  Signup as SignupPage,
  ResetPassword,
  Validation as ValidationPage,
  Team as TeamPage,
  EditProfile as EditProfilePage,
} from 'pages';

export interface RouteConfig {
  label: string;
  path: string;
  component: ComponentType;
  authenticated: boolean;
  exact?: boolean;
  icon?: React.ReactElement<IconProps>;
  showInMenu?: boolean;
}

export enum RouteName {
  SignIn = 'SignIn',
  Home = 'Home',
  Team = 'Team',
  SignUp = 'SignUp',
  ResetPass = 'ResetPass',
  Validation = 'Validation',
  EditProfile = 'EditProfile',
}

const routes: Record<RouteName, RouteConfig> = {
  [RouteName.SignIn]: {
    label: 'Sign In',
    path: '/signin',
    component: SigninPage,
    exact: true,
    authenticated: false,
  },
  [RouteName.SignUp]: {
    label: 'Sign Up',
    path: '/signup',
    component: SignupPage,
    exact: true,
    authenticated: false,
  },
  [RouteName.Home]: {
    label: 'Validations',
    path: '/home',
    component: HomePage,
    exact: true,
    authenticated: true,
    showInMenu: true,
    icon: <StatusGood color="white" />,
  },
  [RouteName.Team]: {
    label: 'Team',
    path: '/team',
    component: TeamPage,
    exact: true,
    authenticated: true,
    showInMenu: true,
    icon: <Group color="white" />,
  },
  [RouteName.Validation]: {
    label: 'Validation',
    path: '/validation/:validationId',
    component: ValidationPage,
    exact: true,
    authenticated: true,
  },
  [RouteName.ResetPass]: {
    label: 'Reset Password',
    path: '/actions/reset_password',
    component: ResetPassword,
    exact: true,
    authenticated: false,
  },
  [RouteName.EditProfile]: {
    label: 'Edit Profile',
    path: '/edit_profile',
    component: EditProfilePage,
    exact: true,
    authenticated: true,
  },
};

export const authenticatedRoutes: RouteConfig[] = [];
export const unauthenticatedRoutes: RouteConfig[] = [];
Object.values(routes).forEach((route) => {
  const dest = route.authenticated
    ? authenticatedRoutes
    : unauthenticatedRoutes;
  dest.push(route);
});

export function useGotoRoute<T>(name: RouteName): (params?: T) => void {
  const { push } = useHistory();
  const route = routes[name];
  return function gotoRoute(params?: T) {
    const path = generatePath(route.path, params);
    push(path);
  };
}

export function generateRoutePath<T>({
  name,
  params,
}: {
  name: RouteName;
  params?: T;
}): string {
  const route = routes[name];
  return generatePath(route.path, params);
}
