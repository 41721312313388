import { Box, Text } from 'grommet';

import { IEmployee } from 'shared/types';
import { UserForm } from './UserForm';
import { useSetEmployee } from '../hooks';

type Props = {
  employee: IEmployee;
  onClose: () => void;
  onUpdate: () => void;
};

export function UpdateUser({ onClose, onUpdate, employee }: Props) {
  const { id, ...userData } = employee;
  const { setEmployee, busy } = useSetEmployee({
    onSuccess: onUpdate,
    employeeId: id,
  });

  return (
    <Box
      width="large"
      background="white"
      pad={{ vertical: 'large', horizontal: 'large' }}
      round="xxsmall"
    >
      <Text
        as="h2"
        size="large"
        textAlign="center"
        margin={{
          top: 'none',
          bottom: 'medium',
        }}
      >
        Edit Employee
      </Text>
      <UserForm
        initialValues={userData}
        onCancel={onClose}
        handleSubmit={setEmployee}
        busy={busy}
      />
    </Box>
  );
}
