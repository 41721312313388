import React from 'react';
import { Box } from 'grommet';
import { MaskedInput } from 'shared/controls';
import { priceMask } from 'shared/constants/masks';

export function DollarValue() {
  return (
    <Box width="small" margin={{ bottom: 'small' }}>
      <MaskedInput
        box={{
          width: 'small',
          margin: {
            bottom: 'small',
          },
        }}
        size="small"
        mask={priceMask(2)}
        name="dollarValue"
      />
    </Box>
  );
}
