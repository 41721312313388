import { useCallback } from 'react';
import { T } from 'rambda';
import 'firebase/auth';

import { useSession } from 'App/session';
import { useFirebase } from 'App/Firebase';
import { TUser } from 'shared';

export function useSignin() {
  const { authenticate, signout } = useSession();
  const firebase = useFirebase();

  const signin = useCallback(
    async (email, password) => {
      const auth = firebase.auth();
      try {
        const userAuth = await auth.signInWithEmailAndPassword(email, password);
        if (!userAuth) {
          signout();
          throw new Error('User is not employee');
        }
        authenticate(userAuth as unknown as TUser);
        return true;
      } catch (e) {
        T();
      }
      return false;
    },
    [authenticate, firebase, signout],
  );

  return {
    signin,
  };
}
