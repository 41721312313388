import * as Yup from 'yup';

import { phoneNumberValidator } from './common';

export const applySendScheme = Yup.object().shape({
  type: Yup.string().oneOf(['phone', 'park']).required(),
  phone: Yup.string().notRequired().when('type', {
    is: 'phone',
    then: phoneNumberValidator,
    otherwise: Yup.string().notRequired(),
  }),
  parkId: Yup.string()
    .notRequired()
    .when('type', {
      is: 'park',
      then: Yup.string()
        .required('Park Id is required')
        .test(
          'test-name',
          'Enter Valid Park Id',
          (value) => !!value && value.length === 6,
        ),
      otherwise: Yup.string().notRequired(),
    }),
});

export type TApplySendInput = Yup.InferType<typeof applySendScheme>;
