import React, { useMemo } from 'react';
import { Box, Image, Button, Text, ImageExtendedProps } from 'grommet';
import { find, propEq } from 'rambda';

import { useGlobalContext } from 'App/globalContext';
import { RouteName, useGotoRoute } from 'App/routes';
import Icons from 'assets/icons';
import { getDiscount } from 'shared/utils/discount';
import { IValidation } from 'shared/types';
import validationTypes from 'shared/constants/validationTypes';
import { statuses } from './data';

interface Props {
  validation: IValidation;
  onClick?: () => void;
}

const Content = ({
  Icon,
  header,
  value,
}: {
  Icon: React.FC<ImageExtendedProps>;
  header: string;
  value: string;
}) => (
  <Box
    basis="1/3"
    border={{ color: '#dfdfdf' }}
    pad={{ vertical: 'large', horizontal: 'medium' }}
    round="xxsmall"
  >
    <Box direction="row" gap="small" align="center">
      <Icon />
      <Box flex="grow">
        <Text>{header}</Text>
        <Text bold color="black">
          {value}
        </Text>
      </Box>
    </Box>
  </Box>
);

export function ValidationCard(props: Props) {
  const {
    validation: {
      type,
      garage,
      description,
      id: validationId,
      status,
      value,
      validationCount,
    },
    onClick,
  } = props;
  const { garages } = useGlobalContext();
  const count = validationCount?.data?.validations_count;
  const vType = find(propEq('value', type), validationTypes)?.value;
  const garageName = find(propEq('id', garage.id), garages)?.name;
  const goto = useGotoRoute(RouteName.Validation);
  const statusData = statuses[status];
  const discountValue = useMemo(() => {
    if (typeof value !== 'number') {
      return 'Custom Rate';
    }
    return getDiscount(vType, value);
  }, [vType, value]);

  return (
    <Box
      elevation="medium"
      round="xsmall"
      background="white"
      pad="medium"
      onClick={() => (onClick ? onClick() : goto({ validationId }))}
    >
      <Box direction="row" gap="small">
        <Content
          Icon={() => <Image src={Icons.Garage} height={28} />}
          header="Garage"
          value={garageName || ''}
        />
        <Content
          Icon={() => <Image src={Icons.Discount} height={28} />}
          header="Discount"
          value={discountValue || ''}
        />
        <Content
          Icon={() => <Image src={Icons.Requirement} height={28} />}
          header="Description"
          value={description}
        />
      </Box>
      <Box
        pad={{ top: 'medium' }}
        direction="row"
        justify="between"
        align="end"
      >
        <Box>
          <Text weight={900} color="#000" margin={{ bottom: 'xxsmall' }}>
            {count || (count === 0 ? '-' : 'Processing...')}
          </Text>
          <Text color="#888">uses this month</Text>
        </Box>
        <Box>
          <Button
            label={
              <Text color={statusData.color} weight={400}>
                {statusData.label}
              </Text>
            }
            color={statusData.color}
          />
        </Box>
      </Box>
    </Box>
  );
}
