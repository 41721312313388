import { useCallback } from 'react';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import { split, length } from 'rambda';

import { useFirebase } from 'App/Firebase';
import { TFunction, TQuery } from 'shared/types';

type ReturnValues = {
  dbQuery: (path: string) => TQuery;
  apiFunction: (name: string) => TFunction;
};

const isEven = (n: number): boolean => n % 2 === 0;

export function useFirebaseApi(): ReturnValues {
  const firebase = useFirebase();

  const dbQuery = useCallback(
    (path) => {
      const dbCall = firebase.firestore();
      return isEven(length(split('/', path)))
        ? dbCall.doc(path)
        : dbCall.collection(path);
    },
    [firebase],
  );

  const apiFunction = useCallback(
    (name) => firebase.functions().httpsCallable(name),
    [firebase],
  );

  return {
    dbQuery,
    apiFunction,
  };
}
