import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { includes } from 'rambda';

import { Layout } from 'layout/unauthenticated';
import { useSession } from '../session';
import {
  unauthenticatedRoutes,
  RouteConfig,
  generateRoutePath,
  RouteName,
} from './helpers';

export function UnauthenticatedRoutes(): JSX.Element {
  const { authenticated } = useSession();
  const { pathname } = useLocation();

  let width = 'medium';

  if (includes(RouteName.SignUp.toLowerCase(), pathname)) {
    width = 'large';
  }

  if (authenticated) {
    return <Redirect to={generateRoutePath({ name: RouteName.Home })} />;
  }

  return (
    <Layout width={width}>
      <Switch>
        {unauthenticatedRoutes.map(
          ({ label, component, exact, path }: RouteConfig) => (
            <Route
              key={label}
              exact={exact}
              component={component}
              path={path}
            />
          ),
        )}
        <Redirect to={generateRoutePath({ name: RouteName.SignIn })} />
      </Switch>
    </Layout>
  );
}
