import React from 'react';
import { Grommet, ThemeType } from 'grommet';
import { ToastProvider } from 'react-toast-notifications';

import Firebase from './Firebase';
import { AppRoutes } from './routes';
import { SessionProvider } from './session';
import theme from './theme';
import GlobalStyle from './theme/GlobalStyle';

import 'react-phone-number-input/style.css';

function App(): React.ReactElement {
  return (
    <Grommet full theme={theme as unknown as ThemeType}>
      <GlobalStyle />
      <Firebase>
        <ToastProvider>
          <SessionProvider>
            <AppRoutes />
          </SessionProvider>
        </ToastProvider>
      </Firebase>
    </Grommet>
  );
}

export default App;
