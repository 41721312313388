import { useCallback, useState, useEffect } from 'react';

import { useToast } from './useToast';
import { getErrorMessage } from '../utils';
import { IFileContent } from '../types';

// const stripBase64Header = (str: string) => str.split(',')[1];

const getFileContent = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve({
        fileName: file.name,
        contentType: file.type,
        uploadContent: reader.result,
        preview: URL.createObjectURL(file),
      });
    reader.onerror = (error) => reject(error);
  });

export const useFileUpload = () => {
  const toast = useToast();
  const [files, setFiles] = useState<IFileContent[]>([]);

  const onUpload = useCallback(
    async (uploads: File[]) => {
      try {
        const result = await Promise.all(uploads.map((f) => getFileContent(f)));
        setFiles(result as IFileContent[]);
      } catch (e) {
        if (e instanceof Error) {
          toast.error(getErrorMessage(e));
        }
      }
    },
    [toast],
  );

  const removeFiles = useCallback(() => {
    setFiles([]);
  }, []);

  useEffect(
    () => () => {
      if (files) {
        files.forEach((file) => URL.revokeObjectURL(file.preview));
      }
    },
    [files],
  );

  return {
    onUpload,
    files,
    file: files[0],
    removeFiles,
  };
};
