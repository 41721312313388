import { Box, Text, Button } from 'grommet';

import { IEmployee } from 'shared/types';
import { useSetEmployee } from '../hooks';

type Props = {
  employee: IEmployee;
  onClose: () => void;
  onRemove: () => void;
};

export function RemoveUser({ onClose, onRemove, employee }: Props) {
  const { id, firstName, lastName } = employee;
  const { removeEmployee, busy } = useSetEmployee({
    onSuccess: onRemove,
    employeeId: id,
  });

  return (
    <Box width="medium" background="white" pad="medium" round="xxsmall">
      <Text
        as="h2"
        size="large"
        textAlign="center"
        margin={{
          top: 'none',
          bottom: 'xsmall',
        }}
      >
        Remove Employee
      </Text>
      <Text as="div" size="medium" textAlign="center">
        Are you sure you want to remove
        <Text weight="bold" as="div">{`${firstName} ${lastName}`}</Text>
        from the company
      </Text>
      <Box gap="small" direction="row" fill margin={{ top: 'medium' }}>
        <Button
          primary
          roundSmall
          label="Remove"
          disabled={busy}
          onClick={() => removeEmployee(employee)}
        />
        <Button roundSmall type="button" label="Close" onClick={onClose} />
      </Box>
    </Box>
  );
}
