import { useEffect } from 'react';

import { useToast } from './useToast';

export const useError = (error: string | null) => {
  const toast = useToast();

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error, toast]);
};
