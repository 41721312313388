/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { Box, Text, Select, SelectProps, BoxExtendedProps } from 'grommet';
import { useField, useFormikContext } from 'formik';

import { ErrorOutput } from '../errors';

interface Props extends SelectProps {
  name: string;
  label?: string;
  box?: BoxExtendedProps;
  noPadding?: boolean;
  placeholder?: string;
}

export function SelectField(props: Props) {
  const { label, name, box, options, ...rest } = props;
  const [{ onChange, value, ...field }, meta] = useField(name);
  const { setFieldValue } = useFormikContext();
  const { touched, error } = meta;

  const handleChange = useCallback(
    ({ option }) => {
      setFieldValue(field.name, option?.value || option);
    },
    [field.name, setFieldValue],
  );

  return (
    <Box fill gap="xxsmall" {...(box || {})}>
      {label && (
        <Text color="light-5" bold size="xsmall">
          {label}
        </Text>
      )}
      <Select
        {...field}
        {...rest}
        labelKey="text"
        valueKey={{ key: 'value', reduce: true }}
        value={value}
        options={options}
        hasError={touched && error}
        onChange={handleChange}
      />
      {touched && error ? <ErrorOutput error={error} /> : <Text />}
    </Box>
  );
}
