export const hoursMinutes = [
  {
    length: [1, 2],
    regexp: /^1[0-9]$|^2[0,1-3]$|^0?[1-9]$|^0$/,
    placeholder: 'HH',
  },
  { fixed: ' : ' },
  {
    length: 2,
    regexp: /^[0-5][0-9]$|^[0-9]$/,
    placeholder: 'mm',
  },
];

export const pricePerTime = [
  {
    length: [1, 2],
    options: [],
    regexp: /^[0-5][0-9]$|^[0-9]$/,
    placeholder: '00',
  },
  { fixed: '.' },
  {
    length: 2,
    options: [],
    regexp: /^[0-9][0-9]$|^[0-9]$/,
    placeholder: '00',
  },
];

export const priceMask = (count: number) => [
  { fixed: '$ ' },
  {
    length: [1, count],
    options: [],
    regexp: /^[0-9]+$/,
    placeholder: '00',
  },
  { fixed: '.' },
  {
    length: 2,
    options: [],
    regexp: /^[0-9][0-9]$|^[0-9]$/,
    placeholder: '00',
  },
];

export const phoneNumber = [
  { fixed: '+' },
  {
    length: 1,
    regexp: /^[0-9]{1,1}$/,
    placeholder: 'x',
  },
  { fixed: ' ' },
  { fixed: '(' },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: 'xxx',
  },
  { fixed: ')' },
  { fixed: ' ' },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: 'xxx',
  },
  { fixed: '-' },
  {
    length: 2,
    regexp: /^[0-9]{1,2}$/,
    placeholder: 'xx',
  },
  { fixed: '-' },
  {
    length: 3,
    regexp: /^[0-9]{1,3}$/,
    placeholder: 'xx(x)',
  },
];

export const parkId = [
  {
    length: 6,
    regexp: /^[0-9]{1,6}$/,
    placeholder: 'xxxxxx',
  },
];
