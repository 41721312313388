import moment from 'moment';

export const getMilisFromString = (str: string, format = 'HH : mm') =>
  moment(str, format).diff(moment().startOf('day'), 'seconds') * 1000;

export const getTimeForDiscount = (milliseconds: number) => {
  if (!milliseconds) return null;
  let time = milliseconds;
  let result = '';

  const days = Math.floor(time / (1000 * 60 * 60 * 24));
  time %= 1000 * 60 * 60 * 24;
  result += days ? `${days} days ` : '';

  const hours = Math.floor(time / (1000 * 60 * 60));
  time %= 1000 * 60 * 60;
  result += hours ? `${hours}h ` : '';

  const minutes = Math.floor(time / (1000 * 60));
  time %= 1000 * 60;
  result += minutes ? `${minutes}m` : '';

  return result;
};
