import Discount from './discount-icon.png';
import Garage from './garage-icon.png';
import Requirement from './requirement-icon.png';

const images = {
  Discount,
  Garage,
  Requirement,
};

export default images;
