import React from 'react';
import { Box, Layer, Button, Text } from 'grommet';

import { useGlobalContext } from 'App/globalContext';
import { useOpenClose } from 'shared/hooks';
import { PageWrapper, ValidationCard } from 'shared';
import { CreateValidation } from './components';

export function Home() {
  const { validations } = useGlobalContext();
  const { open, onOpen, onClose } = useOpenClose();
  return (
    <>
      <PageWrapper>
        <Box align="end">
          <Button
            label={<Text>+ Create New</Text>}
            gap="small"
            primary
            roundSmall
            onClick={onOpen}
            disabled={false}
            size="large"
          />
        </Box>
        <Box margin={{ vertical: 'small' }} />
        <Box gap="medium">
          {validations.map((v) => (
            <ValidationCard key={v.id} validation={v} />
          ))}
        </Box>
      </PageWrapper>
      {open && (
        <Layer
          position="center"
          animation="fadeIn"
          modal
          onClickOutside={onClose}
          onEsc={onClose}
        >
          <CreateValidation onSuccess={onClose} />
        </Layer>
      )}
    </>
  );
}
