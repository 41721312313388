import { useState, useCallback, useEffect } from 'react';

import { useGetSponsorData, useFirebaseApi, useBusy } from 'shared/hooks';
import { normalizeCollection } from 'shared/utils';
import { TFBQuerySnapshot, IEmployee } from 'shared/types';

export function useEmployees() {
  const { getSponsor } = useGetSponsorData();
  const { setBusy, busy } = useBusy();
  const [employees, setEmployees] = useState<IEmployee[]>([]);
  const { dbQuery } = useFirebaseApi();

  const fetchEmployees = useCallback(async () => {
    try {
      setBusy(true);
      const { sponsorId } = await getSponsor();
      const res = await dbQuery(`sponsors/${sponsorId}/employees`).get();
      setEmployees(normalizeCollection<IEmployee>(res as TFBQuerySnapshot));
    } catch (e) {
      setEmployees([]);
    } finally {
      setBusy(false);
    }
  }, [dbQuery, getSponsor, setBusy]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  return {
    employees,
    fetchEmployees,
    busy,
  };
}
