import * as Yup from 'yup';

import { requiredString } from './common';

export const validateValidation = Yup.object({
  garage: requiredString('Select a Garage'),
  type: requiredString('Select Type'),
  description: requiredString('Add Description'),
  dollarValue: Yup.string().when('type', {
    is: 'dollar', // alternatively: (val) => val == true
    then: requiredString('Add Sum').min(3, 'Add Sum'),
  }),
  percentageValue: Yup.number().when('type', {
    is: 'percentage',
    then: Yup.number()
      .min(1, 'Must be more than 0')
      .max(100, 'Should be no more than 100'),
  }),
  hoursValue: Yup.string().when('type', {
    is: 'hours',
    then: Yup.string().required('Required'),
  }),
  customValue: Yup.array().when('type', {
    is: 'custom-rate',
    then: Yup.array()
      .of(
        Yup.object().shape({
          time: Yup.string(),
          priceIncrease: Yup.string(),
        }),
      )
      .required('Required'),
  }),
  basePrice: Yup.string().when('type', {
    is: 'custom-rate',
    then: Yup.string().required('Required'),
  }),
});

export type TValidation = Yup.InferType<typeof validateValidation>;
