import { useMemo } from 'react';
import { Box } from 'grommet';

import { IValidation, EValidationStatus } from 'shared/types';
import { useValidationStatus } from '../hooks';
import { Activate } from './Activate';
import { ActiveActions } from './ActiveActions';

export function Actions({
  validation,
  onUpdate = () => {},
}: {
  validation: IValidation;
  onUpdate?: () => void;
}) {
  const { id, status } = validation;
  const { changeStatus } = useValidationStatus({ onSuccess: onUpdate });

  const component = useMemo(() => {
    if (status === EValidationStatus.READY_TO_ACTIVATE) {
      return (
        <Activate
          changeStatus={(s: EValidationStatus) => {
            changeStatus(s, id);
          }}
        />
      );
    }
    if (status === EValidationStatus.ACTIVE) {
      return <ActiveActions validation={validation} onUpdate={onUpdate} />;
    }
    return null;
  }, [changeStatus, id, onUpdate, status, validation]);

  return (
    <Box align="center" margin={{ vertical: 'xlarge' }}>
      {component}
    </Box>
  );
}
