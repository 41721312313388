import { useCallback, useMemo } from 'react';

import { useGlobalContext } from 'App/globalContext';
import { getOptions } from 'shared/utils';
import {
  useApiFunction,
  useBusy,
  useToast,
  useGetSponsorData,
} from 'shared/hooks';
import { getValidationPayload } from '../utils/helpers';

export function useAddValidation({ onSuccess }: { onSuccess: () => void }) {
  const { garages, getValidations } = useGlobalContext();
  const { setBusy } = useBusy();
  const toast = useToast();
  const { getSponsor } = useGetSponsorData();

  const saveValidationFn = useApiFunction('portal_validations_set_one');

  const options = useMemo(() => getOptions(garages), [garages]);

  const saveValidation = useCallback(
    async (values) => {
      try {
        setBusy(true);
        const { sponsorId } = await getSponsor();
        await saveValidationFn({
          sponsorId,
          data: {
            ...getValidationPayload(values),
          },
        });
        toast.success('Saved successfully');
        onSuccess();
        getValidations();
      } catch (e) {
        if (e instanceof Error) {
          toast.error(e.message);
        }
        setBusy(false);
      }
    },
    [getSponsor, getValidations, onSuccess, saveValidationFn, setBusy, toast],
  );

  return {
    options,
    saveValidation,
  };
}
