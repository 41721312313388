import * as Yup from 'yup';

import { phoneNumberValidator } from './common';

export const validateSignup = Yup.object({
  name: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .required('Required'),
  industry: Yup.string()
    .min(2, 'Must be 2 characters or more')
    .required('Required'),
  address: Yup.object({
    zip: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.object().required('Required'),
    country: Yup.string().required('Required'),
    street: Yup.string().required('Required'),
  }),
  employee: Yup.object({
    firstName: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Must be 2 characters or more')
      .required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    phone: phoneNumberValidator,
  }),
});
