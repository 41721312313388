import { ETypeValues } from 'shared/constants/validationTypes';
import { TCustomRate } from 'shared/types';
import { getTimeForDiscount } from './dates';

const getDollars = (value: number | TCustomRate[]): number | null => {
  if (!Array.isArray(value)) {
    return Number((value / 100).toFixed(2));
  }
  return null;
};

export const getDiscount = (vType: ETypeValues | undefined, value: number) =>
  (vType === 'dollar' && `$${getDollars(value)} off`) ||
  (vType === 'hours' && `${getTimeForDiscount(value)} free`) ||
  (vType === 'percentage' && `${value}% off`);
