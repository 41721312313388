import { fieldStyle } from './controls';

export default {
  select: {
    icons: {
      margin: {
        left: '-20px',
        right: '10px',
      },
    },
    control: {
      open: {
        background: '#e9e9e9',
      },
      extend: (props) => {
        let extraStyles = '';
        if (props.hasError) {
          extraStyles = `
            ${extraStyles}
            border-color: #FF006A;
          `;
        }
        return `
          ${extraStyles}
          ${fieldStyle}
          padding: 0;
          margin: 0;
          outline: 0;
          box-shadow: none;
          input[type="text"] {
            background-color: transparent;
          }
        `;
      },
    },
    options: {
      container: {
        pad: 'xsmall',
      },
      text: {
        size: 'xsmall',
      },
    },
  },
};
