export default {
  calendar: {
    extend: (props) => {
      let extraStyles = '';
      if (props.position) {
        extraStyles = `
            ${extraStyles}
            position: ${props.position}
          `;
      }
      return `
          ${extraStyles}
          & button {
            padding: 6px;
            outline: 0;
            box-shadow: none !important;
          }
          & button[tabindex] {
            padding: inherit;
          }
          & div[tabindex="0"] {
            box-shadow: none !important;
          }
          h4 {
            font-weight: 700;
          }
        `;
    },
  },
};
