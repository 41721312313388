/* eslint-disable react/jsx-props-no-spreading */
import { useCallback } from 'react';
import {
  Box,
  RadioButtonGroup as Group,
  BoxExtendedProps,
  RadioButtonGroupExtendedProps,
} from 'grommet';

interface Props extends RadioButtonGroupExtendedProps {
  sizeTextError?: string;
  box?: BoxExtendedProps;
  handleChange: (v: string) => void;
}

export function RadioButtonGroup(props: Props) {
  const {
    options,
    value,
    box,
    direction = 'row',
    gap = 'medium',
    handleChange,
    ...rest
  } = props;

  const onChange = useCallback(
    (val) => {
      handleChange(val);
    },
    [handleChange],
  );

  return (
    <Box {...(box || {})}>
      <Group
        direction={direction}
        gap={gap}
        options={options}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        {...rest}
      />
    </Box>
  );
}
