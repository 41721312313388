import { Switch, Route, Redirect } from 'react-router-dom';

import { useSession } from 'App/session';
import { GlobalContextProvider } from 'App/globalContext';
import { Layout } from 'layout/authenticated';
import {
  authenticatedRoutes,
  RouteConfig,
  generateRoutePath,
  RouteName,
} from './helpers';

export function AuthenticatedRoutes(): JSX.Element {
  const { authenticated } = useSession();

  if (!authenticated) {
    return <Redirect to={generateRoutePath({ name: RouteName.SignIn })} />;
  }

  return (
    <Layout>
      <GlobalContextProvider>
        <Switch>
          {authenticatedRoutes.map(
            ({ label, component, exact, path }: RouteConfig) => (
              <Route
                key={label}
                exact={exact}
                component={component}
                path={path}
              />
            ),
          )}
          <Route
            key={-1}
            render={() => (
              <Redirect
                to={{
                  pathname: generateRoutePath({ name: RouteName.Home }),
                }}
              />
            )}
          />
        </Switch>
      </GlobalContextProvider>
    </Layout>
  );
}
